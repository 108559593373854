import { useCallback } from 'react';

import gamefoxSDK from '@/utils/gameFoxSDK';

export const useMission = () => {
  const fetchMissions = useCallback(async () => {
    const missions = await gamefoxSDK.getUserQuests();

    return missions;
  }, []);

  return fetchMissions;
};
