import { FC, useCallback, useMemo } from 'react';

import { useLocation } from '@/hooks/useLocation';

import { emitter } from '@/GameSystem/utils/emitter';
import { useStoreState } from '@/hooks/useStore';

type Props = {};

export const Header: FC<Props> = () => {
  const { location, setLocation } = useLocation();

  const user = useStoreState((state) => state?.user);

  const isHideHeader = useMemo(() => {
    return location === '/';
  }, [location]);

  const renderCloseButton = useMemo(() => {
    return (
      <div className="flex justify-center md:w-20 ms:w-16">
        <img
          className="md:h-12 md:w-12 ms:h-10 ms:w-10 cursor-pointer hover:opacity-80"
          src="/images/button_close.png"
          alt=""
        />
      </div>
    );
  }, []);

  const handleBack = useCallback(() => {
    let backPath: string = '';

    if (location === '/game') {
      return emitter.emit('finish', false, 0);
    }

    console.log('location', location);

    switch (location) {
      case '/term':
      case '/reward':
      case '/missions':
      case '/collection':
      case '/select-spirit':
      case '/spin':
        backPath = '/main';
        break;
    }

    if (backPath) {
      console.log('backPath', backPath);

      setLocation(backPath);
    } else {
      history.back();
    }
  }, [location, setLocation]);

  const renderBackButton = useMemo(() => {
    return (
      <div onClick={() => handleBack()} className="flex justify-center md:w-20 ms:w-16">
        <img
          className="md:h-12 md:w-12 ms:h-10 ms:w-10 cursor-pointer hover:opacity-80"
          src="/images/button_back.png"
          alt=""
        />
      </div>
    );
  }, [handleBack]);

  const [leftHeaderAction, headerTextColor] = useMemo(() => {
    switch (location) {
      case '/main':
        return [renderCloseButton, 'text-white'];
      case '/term':
      case '/reward':
      case '/missions':
      case '/collection':
        return [renderBackButton, 'text-orange-80'];
      default:
        return [renderBackButton, 'text-white'];
    }
  }, [location, renderBackButton, renderCloseButton]);

  if (isHideHeader) return null;

  return (
    <div className="flex items-center w-full md:h-16 ms:h-14 z-10">
      {leftHeaderAction}
      <div className="flex-1 flex justify-center">
        <p className={`text-md ${headerTextColor} font-bold`}>Xin chào, {user?.data.customerName}</p>
      </div>
      <div className="flex justify-center md:w-20 ms:w-16">
        <img
          className="md:h-12 md:w-12 ms:h-10 ms:w-10 cursor-pointer hover:opacity-80"
          src="/images/button_sound.png"
          alt=""
        />
      </div>
    </div>
  );
};
