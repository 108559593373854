import { useCallback } from 'react';
import { useLocation as useWouterLocation } from 'wouter';

export const useLocation = () => {
  const [wouterLocation, setWouterLocation] = useWouterLocation();

  const setLocation = useCallback(
    (path: string, params: object = {}) => {
      const paramsString = Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');
      const search = window.location.search || window.location.hash;
      setWouterLocation(`${path}${search || '#'}${paramsString}`);
    },
    [setWouterLocation],
  );

  return {
    location: wouterLocation,
    setLocation,
  };
};
