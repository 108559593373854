import { FC, useMemo } from 'react';

import { useLocation } from '@/hooks/useLocation';

import { Header } from './Header';

type Props = {
  children: React.ReactNode;
};

export const Layout: FC<Props> = ({ children }) => {
  const { location } = useLocation();

  const background = useMemo(() => {
    switch (location) {
      case '/':
        return 'bg-loading-background';
      case '/main':
        return 'bg-main-background';
      case '/select-spirit':
        return 'bg-spirit-select-background';
      case '/term':
      case '/missions':
        return 'bg-term-background';
      case '/reward':
        return 'bg-reward-background';
      case '/collection':
        return 'bg-collection-background';
      case '/game':
        return 'bg-game-background';
      default:
        return 'bg-main-background';
    }
  }, [location]);

  return (
    <div className={`w-screen h-screen flex justify-center overflow-hidden ${background} bg-no-repeat bg-top bg-cover`}>
      <div className={`flex flex-col w-full h-full max-w-screen-ml`}>
        <Header />
        <div className="flex-1 w-full overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};
