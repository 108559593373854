import * as uuid from 'uuid';
import config from '../config';
import gamefoxSDK, { ItemUsageType, MockupData } from './gameFoxSDK';

const mockupData: Partial<MockupData> = {
  user: {
    _id: 'sampleUser',
    data: {
      customerName: 'PHAM TUAN VU PHAM ',
      sh2Mascot: ['dog', 'cat', 'dragon', 'tiger'],
    },
    progressData: {
      loginStreak: 1,
      wordSearchLevel: 7,
      wordSearchPassLvl: 6,
      wordSearchScore: 13,
      wordSearchWeekScore: 13,
    },
  },
  reward: {
    itemId: 'test',
    itemType: 'VOUCHER',
  },
  leaderboard: [],
  notifications: [],
  playLog: {},
};
const defaultMockupData = gamefoxSDK.getMockupData();
defaultMockupData.items.push({
  id: uuid.v4(),
  type: 'VOUCHER',
  usageType: ItemUsageType.ITEM,
  data: {},
});
mockupData.items = defaultMockupData.items;
mockupData.playLog!.questions = defaultMockupData.playLog.questions;

const names = [
  'Henrietta Kilback Henrietta Kilback',
  'Ella Champlin',
  'Patty West IV',
  'Santiago Friesen',
  'Henrietta Kilback',
  'Ella Champlin',
  'Patty West IV',
  'Santiago Friesen',
  'Henrietta Kilback',
  'Ella Champlin',
  'Patty West IV',
  'Santiago Friesen',
];

for (let i = 0; i < 30; i++) {
  mockupData.leaderboard!.push({
    _id: uuid.v4(),
    data: { customerName: names[i] },
    progressData: {
      totalScore: Math.floor(Math.random() * 1000),
      currentWeekScore: Math.floor(Math.random() * 1000),
    },
  });
}

const _DEV_TEMPLATE_KEY = '659fd2ef23038830608af769';
const _DEV_CAMPAIGN_ID = '659fd2ef23038830608af768';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _DEV_INSET = '44;0;31;0';
const _DEV_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMjgyNTQ0OTkyNCIsImRhdGEiOnsidXNlcm5hbWUiOiJDQU8gQU5IIFRIQUkifSwiaWF0IjoxNzA1OTAwMjE3fQ.N0Lw14RypwYS_gw4WSuxuHzwQLiaBVK4h-ZHYwc9aoUQ5nAtMvLSJlT1mJnoVPrgrwonmO-bUQKVLi3LXXwP5SXWyyw1kmDcBHCTZRn_t3hXr33P4PuEVqbuCA1I7QonacB3VOcrwIuMzAQwQnScg5BNsyk0nUWWeK63EltmmKc2j2KvinyZzKBnR9_VH9qMtSB-qsjMEoHTx6W8ptShgltzpyMOpQSoNwoKNI1Qouz9cw5AVMmQgjPzQS0eSf0th1N1FK22bzg4vUxwRsQn6L-k0qrJ8cTlKkaIPUIfJ0SFZznpxnWRa2-wFXWAJkI7CpeBjOtT6YcuqXtrTcgv7A';

const initSdk = async () => {
  if (config.online && config.local) {
    window.history.pushState(
      'Home',
      'Title',
      `/?templateKey=${_DEV_TEMPLATE_KEY}&campaignId=${_DEV_CAMPAIGN_ID}&token=${_DEV_TOKEN}`,
    );
  }

  if (!config.online) {
    window.history.pushState(
      'Home',
      'Title',
      `/?templateKey=${_DEV_TEMPLATE_KEY}&campaignId=${_DEV_CAMPAIGN_ID}&token=${_DEV_TOKEN}&inset=${_DEV_INSET}`,
    );
    gamefoxSDK.setMockupData(mockupData);
  }

  try {
    await gamefoxSDK.init(config.apiUrl, !config.online);
    return true;
  } catch (error) {
    return false;
  }
};

export default initSdk;
