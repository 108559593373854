/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import * as uuid from 'uuid';

const OVERLAY_ID = '__sdk_debug_overlay';
const CONTAINER_ID = '__sdk_debug_container';
declare global {
  interface Window {
    GamefoxSDK?: AndroidGamefoxSDK;
    webkit?: { messageHandlers?: { GamefoxSDK?: IOSGamefoxSDK } };
  }
}

export type SDKMessage = {
  eventType: string;
  eventBody?: unknown;
};
export enum Action {
  ACCESS_POOL = 'ACCESS_POOL',
  PUSH_ITEMS = 'PUSH_ITEMS',
  PROGRESS_QUEST = 'PROGRESS_QUEST',
  CREATE_USER_QUEST = 'CREATE_USER_QUEST',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPDATE_ITEM_METADATA = 'UPDATE_ITEM_METADATA',
  COLLECT_PROGRESS_DATA = 'COLLECT_PROGRESS_DATA',
}
export type ActionResult = {
  itemType: Action;
  result?: any;
  reward: any;
  winCount: number;
};

interface AndroidGamefoxSDK {
  onMessage: (message: string) => void;
}
interface IOSGamefoxSDK {
  postMessage: (message: string) => void;
}

let isShowing = false;
const messages: string[] = [];
const _toggleDebugScreen = () => {
  if (!isShowing) {
    const overlay = document.createElement('div');
    overlay.id = OVERLAY_ID;
    overlay.style.backgroundColor = `rgba(0,0,0,0.5)`;
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.bottom = '0';
    overlay.style.left = '0';
    overlay.style.right = '0';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    overlay.style.zIndex = '9999';

    const container = document.createElement('div');
    container.id = CONTAINER_ID;
    container.style.overflow = 'auto';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.paddingTop = '40px';

    overlay.appendChild(container);
    document.body.appendChild(overlay);

    if (messages.length) {
      messages.forEach((message) => {
        _addDebugMessage(message);
      });
    }

    isShowing = true;
  } else {
    isShowing = false;
    const overlay = document.getElementById(OVERLAY_ID);
    if (overlay) {
      document.body.removeChild(overlay);
    }
  }
};

const _addDebugMessage = (debugMessage: string) => {
  const text = document.createElement('p');
  text.innerHTML = debugMessage;
  text.style.fontSize = '16px';
  text.style.color = 'white';
  const messageContainer = document.getElementById(CONTAINER_ID);
  messageContainer?.appendChild(text);
  messages.push(debugMessage);
};

let messageHandler = (message: SDKMessage) => {
  console.log('Unhandled Message', JSON.stringify(message, null, 2));
};

const DEBUG_EVENTS = {
  ADD_DEBUG_MESSAGE: 'ADD_DEBUG_MESSAGE',
  TOGGLE_DEBUG_SCREEN: 'TOGGLE_DEBUG_SCREEN',
};

window.addEventListener(
  'message',
  (event) => {
    try {
      const message: SDKMessage = JSON.parse(event.data);
      if (message?.eventType === DEBUG_EVENTS.ADD_DEBUG_MESSAGE) {
        _addDebugMessage(JSON.stringify(event.data));
      } else if (message?.eventType === DEBUG_EVENTS.TOGGLE_DEBUG_SCREEN) {
        _toggleDebugScreen();
      } else {
        messageHandler(message);
      }
    } catch (e: unknown) {
      return;
    }
  },
  false,
);

export enum ItemUsageType {
  /** With transactions */
  RESOURCES = 'RESOURCES',
  /** Ticket */
  TICKET = 'TICKET',
  /** Progress Ticket */
  PROGRESS_TICKET = 'PROGRESS_TICKET',
  /** Item with metadata */
  ITEM = 'ITEM',
  /** No use */
  NONE = 'NONE',
}

export type SKDParams = {
  templateKey: string;
  campaignId: string;
  token: string;
  inset?: { top: number; right: number; bottom: number; left: number };
};

export type SystemInfo = {
  currentTime: string;
};
export type Config = {
  [key: string]: any;
};
export type GameInstance = {
  gameId: string;
  config: { [key: string]: any };
};
export type UserData = {
  _id: string;
  data: { [key: string]: any };
  progressData: { [key: string]: any };
};
export type UserQuest = {
  questId: string;
  userQuestId: string;
  name: string;
  description: string;
  goal: number;
  progress: number;
  progressAt: string;
  claimed: boolean;
  claimedAt: string;
  repeat?: {
    amount: number;
    timeAmount: number;
    timeUnit: string;
  };
};
export type Inventory = {
  id: string;
  type: string;
  resources: { [key: string]: { amount: number; type: string } };
  items: {
    id: string;
    itemId: string;
    type: string;
    data: any;
    createdAt: string;
  }[];
  tickets: { id: string; itemId: string; type: string }[];
  progressTickets: { id: string; itemId: string; type: string }[];
  ticketCount: number;
  newTicketCount: number;
};
export type Reward = {
  amount?: number;
  itemId: string;
  itemType: string;
};
export type ItemInfo = {
  id: string;
  type: string;
  data: { [key: string]: any };
  usageType: ItemUsageType;
};
export type Pool = {
  createdAt: string;
  endTime: string;
  itemId: string;
  itemType: string;
  name: string;
  startTime: string;
  status: string;
  weight: number;
  unclaimed?: number;
  remaining?: number;
  workingTimeAmount?: number;
};
export type Checkin = {
  lastCheckIn: number | null;
  loginStreak: number;
};

const sdkParams = {} as SKDParams;
const instanceConfig: Config = {};
let apiUrl = '';
let devMode = true;

export type MockupData = {
  pools: Pool[];
  campaignInfo: { startTime: string; endTime: string };
  user: UserData;
  items: ItemInfo[];
  userQuests: UserQuest[];
  gameInstance: GameInstance;
  instanceConfig: Config;
  systemInfo: SystemInfo;
  inventory: Inventory;
  reward: Reward;
  playLog: {
    level?: number;
    customerId?: string;
    score?: number;
    playTime?: number;
    answers?: Array<string>;
    topic?: string;
    questions?: Array<{ question: string; answer: string }>;
    isDone?: boolean;
    isPass?: boolean;
    data?: any;
    createdAt?: Date;
  };
  leaderboard: {
    _id: string;
    progressData: { [key: string]: any };
    data: { [key: string]: any };
  }[];
  notifications: {
    _id: string;
    data: { [key: string]: any };
    createdAt: string;
  }[];
  missions: any[];
  checkin: Checkin;
};

const _mockTicketItemId = uuid.v4();
const _mockProgressTicketItemId = uuid.v4();
const _mockItemId = uuid.v4();

let mockupData: MockupData = {
  pools: [],
  leaderboard: [],
  campaignInfo: {
    startTime: dayjs().add(-5, 'days').toISOString(),
    endTime: dayjs().add(25, 'days').toISOString(),
  },
  user: { _id: uuid.v4(), data: {}, progressData: {} },
  instanceConfig: {},
  userQuests: [],
  items: [
    {
      id: _mockTicketItemId,
      type: 'TEST_TICKET',
      data: {},
      usageType: ItemUsageType.TICKET,
    },
    {
      id: _mockItemId,
      type: 'TEST_ITEM',
      data: {},
      usageType: ItemUsageType.ITEM,
    },
  ],
  playLog: {
    level: 0,
    customerId: '',
    score: 0,
    playTime: 0,
    answers: [],
    questions: [],
    topic: '',
    isDone: false,
    isPass: false,
    data: {},
    createdAt: new Date(),
  },
  gameInstance: { gameId: 'test', config: {} },
  systemInfo: { currentTime: new Date().toISOString() },
  inventory: {
    id: uuid.v4(),
    items: [],
    tickets: [
      { id: uuid.v4(), itemId: _mockTicketItemId, type: 'TEST_TICKET' },
      { id: uuid.v4(), itemId: _mockTicketItemId, type: 'TEST_TICKET' },
      { id: uuid.v4(), itemId: _mockTicketItemId, type: 'TEST_TICKET' },
    ],
    progressTickets: [
      {
        id: uuid.v4(),
        itemId: _mockProgressTicketItemId,
        type: 'TEST_PROGRESS_TICKET',
      },
      {
        id: uuid.v4(),
        itemId: _mockProgressTicketItemId,
        type: 'TEST_PROGRESS_TICKET',
      },
      {
        id: uuid.v4(),
        itemId: _mockProgressTicketItemId,
        type: 'TEST_PROGRESS_TICKET',
      },
    ],
    resources: {},
    type: 'CAMPAIGN',
    ticketCount: 3,
    newTicketCount: 0,
  },
  reward: { itemId: _mockItemId, itemType: 'TEST_ITEM' },
  notifications: [
    {
      _id: '6516b06984a7635d2397526c',
      data: {
        jobType: 'GIVE_WEEKLY_1ST_REWARDS',
        position: 1,
        itemType: 'EV_500K',
        itemData: [Object],
      },
      createdAt: '2023-10-01T17:00:00.000Z',
    },
  ],
  missions: [],
  checkin: {
    lastCheckIn: new Date().getTime(),
    loginStreak: 1,
  },
};

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  config.baseURL = apiUrl;
  config.headers['x-user-token'] = sdkParams.token;
  config.headers['x-campaign-id'] = sdkParams.campaignId;
  config.headers['x-template-key'] = sdkParams.templateKey;
  return config;
});

const secretMap = new Map<string, string>();
let currentProgressTicketId: string;

const gamefoxSDK = {
  httpInstance: axiosInstance,
  init: async (_apiUrl: string, _devMode?: boolean): Promise<GameInstance> => {
    apiUrl = _apiUrl;
    devMode = !!_devMode;
    // load configuration
    const supportedParams = ['templateKey', 'campaignId', 'token'];
    const otherParams = ['inset'];

    const searchParams = new window.URLSearchParams(window.location.search);
    const hashParams = new window.URLSearchParams(window.location.hash);
    console.log(hashParams, searchParams);

    for (const key of supportedParams) {
      (sdkParams as any)[key] = searchParams.get(key) || hashParams.get(key);
      if (!(sdkParams as any)[key] || typeof (sdkParams as any)[key] !== 'string') {
        throw new Error(`Missing ${key}!`);
      }
    }
    for (const key of otherParams) {
      const param = searchParams.get(key) || hashParams.get(key);
      if (key === 'inset' && param) {
        const frags = param.split(';');
        const inset = {
          top: Number(frags[0]),
          right: Number(frags[1]),
          bottom: Number(frags[2]),
          left: Number(frags[3]),
        };
        sdkParams.inset = inset;
        document.documentElement.style.setProperty('--inset-top', `${inset.top}px`);
        document.documentElement.style.setProperty('--inset-right', `${inset.right}px`);
        document.documentElement.style.setProperty('--inset-bottom', `${inset.bottom}px`);
        document.documentElement.style.setProperty('--inset-left', `${inset.left}px`);
      } else {
        (sdkParams as any)[key] = param;
      }
    }

    if (devMode) {
      return { ...mockupData.gameInstance, config: instanceConfig };
    }
    if (sdkParams.campaignId === 'test') {
      return { gameId: 'test', config: instanceConfig };
    } else {
      const resp = await axiosInstance.get(`/api/game/gameInstance/${sdkParams.templateKey}`);
      if (resp.data?.config) {
        Object.keys(resp.data.config).forEach((key) => {
          instanceConfig[key] = resp.data.config[key];
        });
      }
      return resp.data as GameInstance;
    }
  },
  auth: async (): Promise<UserData> => {
    if (devMode) {
      return mockupData.user;
    }

    const resp = await axiosInstance.get(`/api/game/auth`, {
      headers: { 'x-user-token': sdkParams.token },
    });

    return resp.data;
  },
  checkin: async () => {
    if (devMode) {
      return mockupData.user;
    }
    const resp = await axiosInstance.get(`/api/game/wordsearch/checkIn`, {
      headers: { 'x-user-token': sdkParams.token },
    });

    return resp.data;
  },
  getSystemInfo: async (): Promise<SystemInfo> => {
    if (devMode) {
      return mockupData.systemInfo;
    }
    const resp = await axiosInstance.get(`/api/game/systemInfo`, {
      headers: { 'x-user-token': sdkParams.token },
    });
    return resp.data as SystemInfo;
  },
  getCampaignInfo: async (
    campaignId?: string,
  ): Promise<{ startTime: string; endTime: string; currentTime: string }> => {
    if (devMode) {
      return { ...mockupData.campaignInfo, currentTime: dayjs().toISOString() };
    }
    const resp = await axiosInstance.get(
      `/api/game/campaignInfo?campaignId=${campaignId ? campaignId : sdkParams.campaignId}`,
    );
    return resp.data;
  },
  getItems: async (): Promise<ItemInfo[]> => {
    if (devMode) {
      return mockupData.items;
    }
    const resp = await axiosInstance.get(`/api/game/items`);
    return resp.data as ItemInfo[];
  },
  getUserQuests: async (): Promise<UserQuest[]> => {
    if (devMode) {
      return mockupData.userQuests;
    }
    const resp = await axiosInstance.get(`/api/game/userQuests`, {
      headers: { 'x-user-token': sdkParams.token },
    });
    return resp.data as UserQuest[];
  },
  getInventory: async (campaignId?: string, type: 'CAMPAIGN' | 'GLOBAL' = 'CAMPAIGN'): Promise<Inventory> => {
    if (devMode) {
      return { ...mockupData.inventory, type };
    }

    let _type = type;
    if (!campaignId) {
      _type = 'GLOBAL';
    } else {
      _type = 'CAMPAIGN';
    }
    const _campaignId = campaignId ? campaignId : sdkParams.campaignId;
    const resp = await axiosInstance.get(`/api/game/inventory?type=${_type}&campaignId=${_campaignId}`, {
      headers: { 'x-user-token': sdkParams.token },
    });
    return resp.data as Inventory;
  },

  useTicket: async (ticketId: string, campaignId?: string) => {
    if (devMode) {
      const rewardItem = mockupData.items.find((i) => i.type === mockupData.reward.itemType);
      if (!rewardItem) {
        throw new Error(`Items does not includes itemType: ${mockupData.reward.itemType}`);
      }

      if (rewardItem.usageType === ItemUsageType.TICKET) {
        mockupData.inventory.tickets.push({
          id: uuid.v4(),
          itemId: rewardItem.id,
          type: rewardItem.type,
        });
      } else if (rewardItem.usageType === ItemUsageType.RESOURCES) {
        // mockupData.inventory.resources[rewardItem.type] +=
        //   mockupData.reward.amount || 100;
      } else if (rewardItem.usageType === ItemUsageType.ITEM) {
        mockupData.inventory.items.push({
          createdAt: new Date().toISOString(),
          data: rewardItem.data,
          id: uuid.v4(),
          itemId: mockupData.reward.itemId,
          type: mockupData.reward.itemType,
        });
      } else if (rewardItem.usageType === ItemUsageType.PROGRESS_TICKET) {
        mockupData.inventory.progressTickets.push({
          id: uuid.v4(),
          itemId: mockupData.reward.itemId,
          type: mockupData.reward.itemType,
        });
      }
      return { ...mockupData.reward };
    }

    const resp = await axios.post(
      `${apiUrl}/api/game/useTicket`,
      {
        ticketId,
        campaignId: campaignId ? campaignId : sdkParams.campaignId,
      },
      {
        headers: { 'x-user-token': sdkParams.token },
      },
    );
    return resp.data as Reward;
  },
  startProgressTicket: async (progressTicketId: string, campaignId?: string) => {
    if (devMode) {
      return;
    }

    const resp = await axiosInstance.post('/api/game/startProgressTicket', {
      campaignId: campaignId ? campaignId : sdkParams.campaignId,
      progressTicketId: progressTicketId,
    });
    currentProgressTicketId = progressTicketId;
    const secret = resp.data.secret;
    secretMap.set(progressTicketId, secret);
    return;
  },
  endProgressTicket: async (data: any, progressTicketId?: string, campaignId?: string) => {
    if (devMode) {
      return {
        result: [
          {
            action: 'ACCESS_POOL',
            result: {
              poolId: '65a6cbfcccf4b8b291a2ee67',
              amount: 1,
              poolName: 'LUCKY_WISH',
              itemId: '65a6cbfcccf4b8b291a2ee64',
              itemType: 'LUCKY_WISH',
              itemData: {},
              itemMetadata: {},
            },
          },
        ],
        itemType: 'MAO_1',
        reward: {
          action: 'ACCESS_POOL',
          result: {
            poolId: '65a6cbfcccf4b8b291a2ee67',
            amount: 1,
            poolName: 'LUCKY_WISH',
            itemId: '65a6cbfcccf4b8b291a2ee64',
            itemType: 'LUCKY_WISH',
            itemData: {},
            itemMetadata: {},
          },
        },
        winCount: 1,
      };
    }

    const _progressTicketId = progressTicketId ? progressTicketId : currentProgressTicketId;
    const secret = secretMap.get(_progressTicketId)!;
    const encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
    const resp = await axiosInstance.post('/api/game/sh2/endProgressTicket', {
      campaignId: campaignId ? campaignId : sdkParams.campaignId,
      progressTicketId: _progressTicketId,
      data: encryptData,
    });
    return resp.data as ActionResult;
  },

  useSpin: async (mode: 'LOGIN' | 'PLAY_GAME') => {
    if (devMode) {
      return {
        result: [
          {
            action: 'ACCESS_POOL',
            result: {
              poolId: '65a8ee6920de3f8153d1090e',
              poolName: 'V_TOPUP_5555_SPIN',
              amount: 1,
              itemId: '65a6cbfdccf4b8b291a2ee7f',
              itemData: {},
              itemType: 'V_TOPUP_5555',
            },
          },
        ],
        itemType: 'V_TOPUP_5555',
        reward: {
          action: 'ACCESS_POOL',
          result: {
            poolId: '65a8ee6920de3f8153d1090e',
            poolName: 'V_TOPUP_5555_SPIN',
            amount: 1,
            itemId: '65a6cbfdccf4b8b291a2ee7f',
            itemData: {},
            itemType: 'V_TOPUP_5555',
          },
        },
      };
    }
    try {
      const resp = await axiosInstance.post('/api/game/sh2/spin', {
        source: mode,
      });
      return resp.data as any;
    } catch (e) {
      throw new Error(e);
    }
  },

  getLeaderboard: async (
    campaignId?: string,
    sort?: { [key: string]: 'asc' | 'desc' },
    limit?: number,
    leaderboardKey?: string,
  ): Promise<{
    leaderboard: {
      _id: string;
      data: { [key: string]: any };
      progressData: { [key: string]: any };
    }[];
    position: number;
  }> => {
    if (devMode) {
      return { leaderboard: mockupData.leaderboard, position: 50 };
    }
    let qs = `?campaignId=${campaignId ? campaignId : sdkParams.campaignId}`;
    if (sort) {
      qs += `&sort=${JSON.stringify(sort)}`;
    }
    if (limit) {
      qs += `&limit=${limit}`;
    }
    if (leaderboardKey) {
      qs += `&leaderboardKey=${leaderboardKey}`;
    }
    const leaderboard = await axiosInstance.get(`/api/game/leaderboard${qs}`);
    return leaderboard.data;
  },
  getNotifications: async (): Promise<{ _id: string; data: any; createdAt: string }[]> => {
    if (devMode) {
      return mockupData.notifications;
    }
    const resp = await axiosInstance.get('/api/game/notifications');
    return resp.data;
  },
  ackNotifications: async () => {
    if (devMode) {
      mockupData.notifications = [];
      return;
    }
    await axiosInstance.post('/api/game/ackNotifications');
  },
  getPools: async (): Promise<Pool[]> => {
    if (devMode) {
      return mockupData.pools;
    }

    const resp = await axiosInstance.get(`/api/game/pools`, {
      headers: { 'x-user-token': sdkParams.token },
    });
    return resp.data as Pool[];
  },
  getMissions: async (): Promise<any[]> => {
    if (devMode) {
      return mockupData.missions;
    }

    const resp = await axiosInstance.get(`/api/game/sh2/missions`, {
      headers: { 'x-user-token': sdkParams.token },
    });

    return resp.data as any[];
  },
  getCheckin: async (): Promise<Checkin> => {
    if (devMode) {
      return mockupData.checkin;
    }

    const resp = await axiosInstance.get(`/api/game/sh2/checkIn`, {
      headers: { 'x-user-token': sdkParams.token },
    });

    return resp.data?.progressData || (null as Checkin);
  },
  registerHandler: (handler: (message: SDKMessage) => void) => {
    messageHandler = handler;
  },
  postMessage: (message: SDKMessage) => {
    if (window.GamefoxSDK) {
      window.GamefoxSDK.onMessage(JSON.stringify(message));
    }
    if (window.webkit?.messageHandlers?.GamefoxSDK) {
      window.webkit.messageHandlers.GamefoxSDK.postMessage(JSON.stringify(message));
    }
  },
  getParams: (): SKDParams => sdkParams,
  getInstanceConfig: () => instanceConfig,
  isShowingDebugScreen: () => isShowing,
  addDebugMessage: _addDebugMessage,
  toggleDebugScreen: _toggleDebugScreen,
  DEBUG_EVENTS,
  setMockupData: (data: Partial<MockupData>) => {
    mockupData = { ...mockupData, ...data };
  },
  getMockupData: (): MockupData => {
    return mockupData;
  },
  equipMascots: async (mascots: string[]): Promise<string[]> => {
    try {
      const resp = await axiosInstance.post(
        `/api/game/sh2/equipMascots`,
        {
          mascots,
        },
        {
          headers: { 'x-user-token': sdkParams.token },
        },
      );

      return resp.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};

export default gamefoxSDK;
