import { FC, useMemo } from 'react';
import styles from './styles.module.css';

import { useLocation } from '@/hooks/useLocation';
import { useStoreState } from '@/hooks/useStore';

type Props = {};

export const CollectionScreen: FC<Props> = () => {
  const { setLocation } = useLocation();

  const inventory = useStoreState((state) => state.inventory);

  const [spiritPieces, total] = useMemo(() => {
    if (!inventory) return [];

    const mappedPieces = Object.values(inventory.resources);

    let totalPieces = 0;

    mappedPieces.forEach((piece) => {
      totalPieces += piece.amount;
    });

    return [mappedPieces, totalPieces];
  }, [inventory]);

  return (
    <div className="w-full h-full flex flex-1 flex-col items-center overflow-y-hidden">
      <div className="text-xl text-blue-90 font-bold">Bộ sưu tập</div>
      <div className={`w-full flex flex-col items-center flex-1 ${styles.container} pr-5 pl-5 pb-5`}>
        <div className={`flex flex-col items-center ${styles.board} z-10 relative`}>
          <img className="w-full h-auto" src="/images/collection_golden_board.png" alt="" />
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <p className="text-ms text-red font-bold">Tổng giá trị giải thưởng</p>
            <p className="text-base leading-4 text-white font-bold">300,000,000 VND</p>
            <p className="text-ms text-red"></p>
          </div>
        </div>
        <div
          className={`w-full flex-1 bg-collection-content-background bg-cover bg-center bg-no-repeat ${styles.content} grid grid-cols-3 gap-3`}
        >
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              {spiritPieces.find((piece) => piece.type === 'TY_1' && piece.amount) &&
              spiritPieces.find((piece) => piece.type === 'TY_2' && piece.amount) ? (
                <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
              ) : null}
              <p className="text-base leading-4 text-white font-bold -mb-1">Tý</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'TY_1' && piece.amount)
                      ? '/images/rat_piece_1.png'
                      : '/images/rat_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'TY_2' && piece.amount)
                      ? '/images/rat_piece_2.png'
                      : '/images/rat_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'SUU_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'SUU_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Sửu</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'SUU_1' && piece.amount)
                      ? '/images/ox_piece_1.png'
                      : '/images/ox_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'SUU_2' && piece.amount)
                      ? '/images/ox_piece_2.png'
                      : '/images/ox_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'DAN_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'DAN_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Dần</p>
              <div className="flex h-4/5 w-4/5 justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'DAN_1' && piece.amount)
                      ? '/images/tiger_piece_1.png'
                      : '/images/tiger_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'DAN_2' && piece.amount)
                      ? '/images/tiger_piece_2.png'
                      : '/images/tiger_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'MAO_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'MAO_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Mão</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'MAO_1' && piece.amount)
                      ? '/images/cat_piece_1.png'
                      : '/images/cat_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'MAO_2' && piece.amount)
                      ? '/images/cat_piece_2.png'
                      : '/images/cat_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'THIN_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'THIN_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Thìn</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'THIN_1' && piece.amount)
                      ? '/images/dragon_piece_1.png'
                      : '/images/dragon_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'THIN_2' && piece.amount)
                      ? '/images/dragon_piece_2.png'
                      : '/images/dragon_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'RAN_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'RAN_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Tỵ</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'RAN_1' && piece.amount)
                      ? '/images/snake_piece_1.png'
                      : '/images/snake_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'RAN_2' && piece.amount)
                      ? '/images/snake_piece_2.png'
                      : '/images/snake_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'NGO_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'NGO_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Ngọ</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'NGO_1' && piece.amount)
                      ? '/images/horse_piece_1.png'
                      : '/images/horse_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'NGO_2' && piece.amount)
                      ? '/images/horse_piece_2.png'
                      : '/images/horse_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'MUI_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'MUI_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Mùi</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'MUI_1' && piece.amount)
                      ? '/images/goat_piece_1.png'
                      : '/images/goat_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'MUI_2' && piece.amount)
                      ? '/images/goat_piece_2.png'
                      : '/images/goat_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'THAN_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'THAN_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Thân</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'THAN_1' && piece.amount)
                      ? '/images/monkey_piece_1.png'
                      : '/images/monkey_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'THAN_2' && piece.amount)
                      ? '/images/monkey_piece_2.png'
                      : '/images/monkey_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'DAU_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'DAU_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Dậu</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'DAU_1' && piece.amount)
                      ? '/images/chicken_piece_1.png'
                      : '/images/chicken_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'DAU_2' && piece.amount)
                      ? '/images/chicken_piece_2.png'
                      : '/images/chicken_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'TUAT_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'TUAT_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Tuất</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'TUAT_1' && piece.amount)
                      ? '/images/dog_piece_1.png'
                      : '/images/dog_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'TUAT_2' && piece.amount)
                      ? '/images/dog_piece_2.png'
                      : '/images/dog_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full h-fit relative">
            <img className="w-full h-auto" src="/images/collection_item_background.png" alt="" />
            {spiritPieces.find((piece) => piece.type === 'HOI_1' && piece.amount) &&
            spiritPieces.find((piece) => piece.type === 'HOI_2' && piece.amount) ? (
              <img className="h-6 w-6 absolute -right-3 top-0 z-10" src="/images/collected.png" alt="" />
            ) : null}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-base leading-4 text-white font-bold -mb-1">Hợi</p>
              <div className="flex h-4/5 w-4/5  justify-center items-center mb-1">
                <img
                  className="h-full"
                  src={
                    spiritPieces.find((piece) => piece.type === 'HOI_1' && piece.amount)
                      ? '/images/pig_piece_1.png'
                      : '/images/pig_piece_1_disabled.png'
                  }
                  alt=""
                />
                <img
                  className="h-full -ml-4"
                  src={
                    spiritPieces.find((piece) => piece.type === 'HOI_2' && piece.amount)
                      ? '/images/pig_piece_2.png'
                      : '/images/pig_piece_2_disabled.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-2">
          <p className="text-base text-orange-80 italic font-bold">Số lượng mảnh đã nhận: {total} mảnh</p>
        </div>
        <img
          onClick={() => setLocation('/main')}
          className="w-1/2 md:w-1/3 h-auto mt-1 cursor-pointer hover:opacity-50"
          src="/images/button_main.png"
          alt=""
        />
      </div>
    </div>
  );
};
