import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { init } from '@/GameSystem/main';
import { emitter } from '@/GameSystem/utils/emitter';
import gamefoxSDK from '@/utils/gameFoxSDK';
import { useStoreActions, useStoreState } from '@/hooks/useStore';
import { usePopup } from '@/hooks/usePopup';
import { useLocation } from '@/hooks/useLocation';

type Props = {};

export const GameScreen: FC<Props> = () => {
  const ref = useRef<HTMLCanvasElement>(null);

  const [isGameFinished, setGameFinished] = useState<boolean>(false);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

  const { setVictoryPopupVisible, setLosePopupVisible } = usePopup();
  const setGiftData = useStoreActions((actions) => actions.setGiftData);
  const setSahaEnergy = useStoreActions((actions) => actions.setSahaEnergy);

  const inventory = useStoreState((state) => state.inventory);
  const user = useStoreState((state) => state.user);
  const [sended, setSended] = useState<boolean>(false);

  const { setLocation } = useLocation();

  useEffect(() => {
    if (!ref.current) return;
    const gamePromise = init(ref.current, user.data.sh2Mascot).then(async (game) => {
      return game;
    });
    return () => {
      gamePromise.then((game) => game.dispose());
    };
  }, [inventory, user]);

  const onFinishGame = useCallback(
    async (isVictory: boolean) => {
      if (sended) return;
      console.log('called 2 times');
      const result = await gamefoxSDK.endProgressTicket({
        isWin: isVictory,
        score: 100,
      });
      setSended(true);
      setSahaEnergy(result?.winCount || 0);
      if (result?.winCount === 6) {
        setLocation('/spin');
        return;
      }
      setGiftData(result);
      setGameFinished(true);

      isVictory ? setVictoryPopupVisible(true) : setLosePopupVisible(true);
    },
    [sended, setGiftData, setLocation, setLosePopupVisible, setSahaEnergy, setVictoryPopupVisible],
  );

  useEffect(() => {
    const handleFinishLoading = () => {
      setFinishedLoading(true);
    };

    emitter.on('finishedLoading', handleFinishLoading);
    return () => {
      emitter.off('finishedLoading', handleFinishLoading);
    };
  });

  useEffect(() => {
    emitter.on('finish', onFinishGame);
    return () => {
      emitter.off('finish', onFinishGame);
    };
  }, [onFinishGame]);

  return (
    <div className="w-full h-full flex flex-1 flex-col items-center overflow-y-hidden">
      <div className="absolute w-full h-full z-10">
        {!isGameFinished && <canvas className="w-full h-full" ref={ref} />}
      </div>

      {!finishedLoading && (
        <div className="flex flex-col z-20 absolute bottom-40 w-full items-center gap-2 max-w-md">
          <div className="flex w-[60%] bg-[#DFEBFF] rounded-full overflow-hidden h-4 p-0.5 shadow-xl">
            <div className="flex bg-gradient-to-b from-[#ED1F29] to-[#710E11] w-20 progressLoadingBar rounded-full"></div>
          </div>
          <div className=" h-50 text-white text-md text-center">Chờ chút nhé</div>
        </div>
      )}
    </div>
  );
};
