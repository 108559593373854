import gamefoxSDK from '@/utils/gameFoxSDK';

//Navigate
// Mở tài khoản EKYC
const navigateOpenEKYCAccount = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'OpenEKYCAccount',
    },
  });
};

// Chuyển khoản liên ngân hàng:
const navigateInterbankTransfer = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'InterbankTransfer',
    },
  });
};

//Giao dịch thanh toán hóa đơn từ có giá trị bất kỳ:
const navigatePayBills = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'PayBills',
    },
  });
};

//Giao dịch Nạp tiền điện thoại có giá trị từ 100,000đ trở lên:
const navigatePhoneRecharge = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'PhoneRecharge',
    },
  });
};

//Giao dịch Nạp ví điện tử từ 100.000đ::
const navigateEwalletTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'EwalletTransaction',
    },
  });
};

//Giao dịch Nạp phí đường bộ từ 100,000đ/GD:
const navigateRoadTollRechargeTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'RoadTollRechargeTransaction',
    },
  });
};

//Khách hàng duy trì số dư bình quân tài khoản thanh toán đạt giá trị tối thiểu 1,000,000đ /tuần
const navigateAverageAccountBalanceTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'AverageAccountBalanceTransaction',
    },
  });
};

// Giao dịch mở mới tiết kiệm kỳ hạn 6 tháng có giá trị từ 10,000,000đ
const navigateSavingAccountTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'SavingAccountTransaction',
    },
  });
};

//Khách hàng vay online cầm cố sổ tiết kiệm theo hạn mức vay
const navigatePledgeSavingAccount = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'PledgeSavingAccount',
    },
  });
};

// Giao dịch khách hàng mở thấu chi online thành công
const navigateOpenOverDraftTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'OpenOverDraftTransaction',
    },
  });
};

//Dư nợ bình quân của tài khoản thấu chi online từ 10,000,000đ/ tuần
const navigateOverDraftAverageTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'OverDraftAverageTransaction',
    },
  });
};

//Mở thẻ ghi nợ quốc tế:
const navigateOpenCreditCard = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'OpenCreditCard',
    },
  });
};

//Mở thẻ ghi nợ nội địa:
const navigateInterCreditCard = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'InterCreditCard',
    },
  });
};

// Chi tiêu của thẻ tín dụng:
const navigateOpenCreditCardTransaction = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'OpenCreditCardTransaction',
    },
  });
};

// Giao dịch mua chứng chỉ quỹ có giá trị tối thiểu từ 500,000 đ/gd:
const navigateBuyFundCertificates = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'BuyFundCertificates',
    },
  });
};

//Giao dịch thanh toán hợp đồng bảo hiểm :
const navigateInsuranceContractPayment = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'InsuranceContractPayment',
    },
  });
};

//Chia sẻ link game
const navigateShareGame = (urlGame: string, imageUrl: string) => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'ShareGame',
      url: urlGame,
      imageUrl: imageUrl,
    },
  });
};

// Thoát game:
const navigateExitGame = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'ExitGame',
    },
  });
};

//Bấm nút “Sử dụng ngay” quà Topup tại giỏ quà
const navigateUseTopupReward = (linkVoucher: string) => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'UseTopupReward',
      voucherId: linkVoucher,
    },
  });
};

// Bấm nút “Chi tiết” quà Điểm Reward
const navigateRewardPointInfomation = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'RewardPointInfomation',
    },
  });
};

//Bấm nút “Liên hệ ngay” quà Iphone 15 Pro
const navigateContact = () => {
  gamefoxSDK.postMessage({
    eventType: 'NAVIGATE',
    eventBody: {
      destination: 'Contact',
    },
  });
};

export default {
  navigateOpenEKYCAccount,
  navigateInterbankTransfer,
  navigatePayBills,
  navigatePhoneRecharge,
  navigateEwalletTransaction,
  navigateRoadTollRechargeTransaction,
  navigateAverageAccountBalanceTransaction,
  navigateSavingAccountTransaction,
  navigatePledgeSavingAccount,
  navigateOpenOverDraftTransaction,
  navigateOverDraftAverageTransaction,
  navigateOpenCreditCard,
  navigateInterCreditCard,
  navigateOpenCreditCardTransaction,
  navigateBuyFundCertificates,
  navigateInsuranceContractPayment,
  navigateShareGame,
  navigateExitGame,
  navigateUseTopupReward,
  navigateRewardPointInfomation,
  navigateContact,
};
