import { FC } from 'react';
import styles from './styles.module.css';

import { usePopup } from '@/hooks/usePopup';
import { useLocation } from '@/hooks/useLocation';
import navigate from '@/utils/navigateFunction';

type Props = {
  isVisible: boolean;
};

export const ErrorPopup: FC<Props> = ({ isVisible }) => {
  const { setLocation } = useLocation();
  const { setErrorPopupVisible } = usePopup();

  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <div
          style={{ width: '100%', height: '50%' }}
          className="max-w-screen-ml flex flex-col items-center justify-center relative"
        >
          <div className="absolute top-0 right-4 cursor-pointer hover:opacity-50 z-30">
            <img
              onClick={() => {
                setErrorPopupVisible(false);
                navigate.navigateExitGame();
              }}
              className="h-6 w-6"
              src="/images/popup_scroll_button_close.png"
              alt=""
            />
          </div>
          <img className={`w-1/4 mb-4 z-10 -mt-14 md:-mt-24 ${styles.image}`} src="/images/error.png" alt="" />
          <p className="text-sm ml:text-base text-red font-bold z-10">Có lỗi sảy ra</p>
          <img src="/images/popup_error.png" alt="" className="w-full h-auto absolute inset-0" />
        </div>
      </div>
    </div>
  );
};
