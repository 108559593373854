import { FC, useCallback } from 'react';

import { useLocation } from '@/hooks/useLocation';
import { usePopup } from '@/hooks/usePopup';
import { useStoreState } from '@/hooks/useStore';

type Props = {
  isVisible: boolean;
};

export const LosePopup: FC<Props> = ({ isVisible }) => {
  const { setLocation } = useLocation();
  const user = useStoreState((state) => state.user);

  const { setLosePopupVisible } = usePopup();

  const handleContinuePlay = useCallback(async () => {
    setLosePopupVisible(false);
    setLocation('/main');
  }, [setLocation, setLosePopupVisible]);

  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <div
          style={{ width: '95%', height: '75%' }}
          className="max-w-screen-ml bg-popup-lose bg-no-repeat bg-center bg-contain flex flex-col items-center justify-center relative"
        >
          <img className="w-2/5 mb-8" src={`/images/saha_energy_${user?.progressData.winCount}_gray.png`} alt="" />
          <p className="text-sm ml:text-base text-white font-bold">Hãy cố gắng hơn trong lượt</p>
          <p className="text-sm ml:text-base text-white font-bold">tiếp theo nhé!</p>
        </div>

        <div
          onClick={() => handleContinuePlay()}
          className="flex flex-1 items-center justify-center pl-2 pr-4 cursor-pointer hover:opacity-80 absolute bottom-10"
        >
          <img className="w-1/2 h-auto sm:w-2/3" src="/images/button_continue.png" alt="" />
        </div>
      </div>
    </div>
  );
};
