import { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';

import { useLocation } from '@/hooks/useLocation';
import { useMission } from '@/hooks/useMission';
import { usePopup } from '@/hooks/usePopup';
import navigate from '@/utils/navigateFunction';

type Props = {};

export const MissionScreen: FC<Props> = () => {
  const { setLocation } = useLocation();
  const { setErrorPopupVisible } = usePopup();

  const fetchMissions = useMission();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [missions, setMissions] = useState([]);

  const handleFetchMissions = useCallback(async () => {
    try {
      setLoading(true);
      const missions = await fetchMissions();

      setMissions(missions);
    } catch (error) {
      setErrorPopupVisible(true);
    } finally {
      setLoading(false);
    }
  }, [fetchMissions, setErrorPopupVisible]);

  useEffect(() => {
    handleFetchMissions();
  }, [handleFetchMissions]);

  const generateMissionInfo = useCallback((mission) => {
    let name = '';
    let image = '';
    let action = () => {};

    let timeunit = ' / ngày';

    switch (mission.repeat.timeUnit) {
      case 'lifetime':
        timeunit = '';
        break;
      case 'd':
        timeunit = ' / ngày';
        break;
      case 'w':
        timeunit = ' / tuần';
        break;
    }

    const repeat = `${mission.repeat.amount || 1} lần${timeunit}`;

    switch (mission.name) {
      case 'CUSTOMER_OPEN_ACCOUNT_QUEST':
        name = 'Mở mới tài khoản eKYC thành công';
        image = '/images/mission_open_account.png';
        action = navigate.navigateOpenEKYCAccount;
        break;
      case 'PAYMENT_QUEST':
        name = 'Giao dịch thanh toán hóa đón có giá trị bất kỳ';
        image = '/images/mission_payment.png';
        action = navigate.navigatePayBills;
        break;
      case 'TRANSFER_QUEST':
        name = 'Chuyển khoản liên ngân hàng có giá trị từ 100,000 VND';
        image = '/images/mission_transfer.png';
        action = navigate.navigateInterbankTransfer;
        break;
      case 'MOBILE_QUEST':
        name = 'Nạp tiền điện thoại có giá trị từ 100,000 VND';
        image = '/images/mission_mobile.png';
        action = navigate.navigatePhoneRecharge;
        break;
      case 'ROAD_TOLLS_QUEST':
        name = 'Nạp phí đường bộ có giá trị từ 100,000 VND';
        image = '/images/mission_road_tolls.png';
        action = navigate.navigateRoadTollRechargeTransaction;
        break;
      case 'EWALLET_QUEST':
        name = 'Nạp ví điện tử có giá trị từ 100,000 VND';
        image = '/images/mission_ewallet.png';
        action = navigate.navigateEwalletTransaction;
        break;
      case 'OPEN_OVERDRAFT_QUEST':
        name = 'Mở thấu chi online thành công';
        image = '/images/mission_open_overdraft.png';
        action = navigate.navigateOpenOverDraftTransaction;
        break;
      case 'NEW_CARD_QUEST':
        name = 'Mở mới thẻ tín dụng quốc tế Visa/Mastercard';
        image = '/images/mission_new_card.png';
        action = navigate.navigateOpenCreditCardTransaction;
        break;
      case 'CREDIT_TRANSACTION_QUEST':
        name = 'Giao dịch mua chứng chỉ quỹ tối thiểu 500,000 VND';
        image = '/images/mission_credit_transaction.png';
        action = navigate.navigateBuyFundCertificates;
        break;
      case 'PAYMENT_INSURANCE_QUEST':
        name = 'Thanh toán hợp đồng Daiichi trực tuyến trên app SHB Mobile/SHB có giá trị từ 1,000,000 VND';
        image = '/images/mission_payment_insurance.png';
        action = navigate.navigateInsuranceContractPayment;
        break;
    }

    return {
      name,
      image,
      claimed: mission.claimed,
      repeat,
      goal: mission.goal,
      action,
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-1 flex-col items-center overflow-y-hidden">
      <div className="text-xl text-blue-90 font-bold">Nhiệm vụ</div>
      <div className={`w-full flex flex-col items-center flex-1 ${styles.container} pr-5 pl-5 pb-5`}>
        <div className={`w-full flex-1 ${styles.content}`}>
          {isLoading ? (
            <p className="text-base text-orange-80 text-center font-bold">Đang lấy thông tin nhiệm vụ</p>
          ) : (
            <>
              {missions.map((mission, index) => {
                const missionInfo = generateMissionInfo(mission);
                return (
                  <div key={index} className="w-full flex items-center justify-center relative mb-4">
                    <img className="w-full h-auto" src="/images/mission_background.png" alt="" />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="pl-4 pr-4">
                        <img className="h-10 ml:h-12" src={missionInfo.image} alt="" />
                      </div>
                      <div className="flex flex-col justify-center flex-1">
                        <p className="text-ms ml:text-xs leading-3 text-blue-90 font-bold">{missionInfo.name}</p>
                        <p className="text-ms text-orange-80">+ {missionInfo.goal} lượt chơi</p>
                      </div>
                      <div className="w-1/4 flex flex-col justify-center items-end pr-1">
                        {missionInfo.claimed ? (
                          <img className="h-6 w-auto" src="/images/action_mission_complete.png" alt="" />
                        ) : (
                          <div className="flex flex-col items-center">
                            <img
                              onClick={() => missionInfo.action()}
                              className="h-6 w-auto cursor-pointer hover:opacity-50"
                              src="/images/action_do.png"
                              alt=""
                            />
                            <p className="text-ms text-blue-90 mt-1">{missionInfo.repeat}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>

        <img
          onClick={() => setLocation('/main')}
          className="w-1/2 md:w-1/3 h-auto mt-3 cursor-pointer hover:opacity-50"
          src="/images/button_main.png"
          alt=""
        />
      </div>
    </div>
  );
};
