import React, { FC, createContext, useState } from 'react';

import { VictoryPopup } from './Victory';
import { LosePopup } from './Lose';
import { OutOfTurnPopup } from './OutOfTurn';
import { LuckySpinPopup } from './LuckySpin';
import { ErrorPopup } from './Error';
import { IphonePopup } from './IphonePopup';

type Props = {
  children: React.ReactNode;
};

export const PopupContext = createContext<{
  victoryPopupVisible: boolean;
  setVictoryPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  losePopupVisible: boolean;
  setLosePopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  outOfTurnPopupVisbile: boolean;
  setOutOfTurnPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  luckySpinPopupVisible: boolean;
  setLuckySpinPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  errorPopupVisible: boolean;
  setErrorPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  iphonePopupVisible: boolean;
  setIphonePopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  victoryPopupVisible: false,
  setVictoryPopupVisible: () => {},
  losePopupVisible: false,
  setLosePopupVisible: () => {},
  outOfTurnPopupVisbile: false,
  setOutOfTurnPopupVisible: () => {},
  luckySpinPopupVisible: false,
  setLuckySpinPopupVisible: () => {},
  errorPopupVisible: false,
  setErrorPopupVisible: () => {},
  iphonePopupVisible: false,
  setIphonePopupVisible: () => {},
});

export const PopupProvider: FC<Props> = ({ children }) => {
  const [victoryPopupVisible, setVictoryPopupVisible] = useState<boolean>(false);
  const [losePopupVisible, setLosePopupVisible] = useState<boolean>(false);
  const [outOfTurnPopupVisbile, setOutOfTurnPopupVisible] = useState<boolean>(false);
  const [luckySpinPopupVisible, setLuckySpinPopupVisible] = useState<boolean>(false);
  const [errorPopupVisible, setErrorPopupVisible] = useState<boolean>(false);
  const [iphonePopupVisible, setIphonePopupVisible] = useState<boolean>(false);

  return (
    <PopupContext.Provider
      value={{
        victoryPopupVisible,
        setVictoryPopupVisible,
        losePopupVisible,
        setLosePopupVisible,
        outOfTurnPopupVisbile,
        setOutOfTurnPopupVisible,
        luckySpinPopupVisible,
        setLuckySpinPopupVisible,
        errorPopupVisible,
        setErrorPopupVisible,
        iphonePopupVisible,
        setIphonePopupVisible,
      }}
    >
      {children}
      <VictoryPopup isVisible={victoryPopupVisible} />
      <LosePopup isVisible={losePopupVisible} />
      <OutOfTurnPopup isVisible={outOfTurnPopupVisbile} />
      <LuckySpinPopup isVisible={luckySpinPopupVisible} />
      <ErrorPopup isVisible={errorPopupVisible} />
      <IphonePopup isVisible={iphonePopupVisible} />
    </PopupContext.Provider>
  );
};
