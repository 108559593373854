import { action, Action, createStore } from 'easy-peasy';
import gamefoxSDK from './gameFoxSDK';

export interface StoreModel {
  soundMode: boolean;
  setSoundMode: Action<StoreModel, boolean>;
  user: null | Awaited<ReturnType<typeof gamefoxSDK.auth>>;
  setUser: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.auth>> | null>;
  inventory: null | Awaited<ReturnType<typeof gamefoxSDK.getInventory>>;
  setInventory: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.getInventory>>>;
  setNotifications: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.getNotifications>>>;
  notifications: [] | Awaited<ReturnType<typeof gamefoxSDK.getNotifications>>;
  checkin:
    | {
        lastCheckIn: number | null;
        loginStreak: number;
      }
    | Awaited<ReturnType<typeof gamefoxSDK.getCheckin>>;
  setCheckin: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.getCheckin>>>;
  setEquipedSpirit: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.equipMascots>>>;
  iphoneClaimDate: string | null;
  setIphoneClaimDate: Action<StoreModel, string | null>;
  giftData: null | Awaited<ReturnType<typeof gamefoxSDK.endProgressTicket>>;
  setGiftData: Action<StoreModel, Awaited<ReturnType<typeof gamefoxSDK.endProgressTicket>>>;
  setSahaEnergy: Action<StoreModel, number>;
}

const store = createStore<StoreModel>({
  soundMode: false,
  setSoundMode: action((state, payload) => {
    state.soundMode = payload;
  }),
  user: null,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  inventory: null,
  setInventory: action((state, payload) => {
    state.inventory = payload;
  }),
  setNotifications: action((state, payload) => {
    state.notifications = payload;
  }),
  notifications: [],
  checkin: {
    lastCheckIn: null,
    loginStreak: 0,
  },
  setCheckin: action((state, payload) => {
    state.checkin = payload;
  }),
  setEquipedSpirit: action((state, payload) => {
    state.user.data.sh2Mascot = payload;
  }),
  iphoneClaimDate: null,
  setIphoneClaimDate: action((state, payload) => {
    state.iphoneClaimDate = payload;
  }),
  giftData: null,
  setGiftData: action((state, payload) => {
    state.giftData = payload;
  }),
  setSahaEnergy: action((state, payload) => {
    state.user.progressData.winCount = payload;
  }),
});

export default store;
