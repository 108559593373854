/* eslint-disable @typescript-eslint/no-explicit-any */
export const MoveType = {
  UP: 1,
  DOWN: 2,
  LEFT: 3,
  RIGHT: 4,
};

export default class Board {
  state: any[] = [];
  matches: any[] = [];
  width = 6;
  height = 6;
  matchedCells: any[] = [];
  colData: any[] = [];
  cellTypeCount = 4;
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  getCol(x: number) {
    this.colData.length = 0;
    for (let i = 0; i < this.height; i++) {
      this.colData.push(this.getCell(x, i));
    }
    return this.colData;
  }
  getState(pos: number) {
    return this.state[pos];
  }
  setState(pos: number, value: any) {
    this.state[pos] = value;
  }
  setRandomState(pos: number) {
    this.state[pos] = Math.floor(Math.random() * this.cellTypeCount);
  }
  swap(pos1: number, pos2: number) {
    const val1 = this.state[pos1];
    const val2 = this.state[pos2];
    this.setState(pos1, val2);
    this.setState(pos2, val1);
  }
  getCellCount() {
    return this.width * this.height;
  }
  getCell(x: number, y: number) {
    if (x >= this.width || y >= this.height) {
      return undefined;
    }
    return this.state[y * this.width + x];
  }
  getX(pos: number) {
    return pos % this.width;
  }
  getY(pos: number) {
    return Math.floor(pos / this.width);
  }
  checkCellsAdjacence(pos1: number, pos2: number) {
    const newY = this.getY(pos2);
    const oldY = this.getY(pos1);
    if (Math.abs(pos1 - pos2) === 1 && newY === oldY) {
      return pos1 > pos2 ? MoveType.LEFT : MoveType.RIGHT;
    }
    if (Math.abs(pos1 - pos2) === this.width) {
      return pos1 > pos2 ? MoveType.UP : MoveType.DOWN;
    }
    return null;
  }
  getPos(x: number, y: number) {
    return y * this.width + x;
  }
  findAllMatches() {
    const { matches, width, height, matchedCells } = this;
    matches.length = 0;

    for (let y = 0; y < height; y++) {
      let x = 0;
      let currentType = undefined;
      matchedCells.length = 0;
      matchedCells.push(this.getPos(x, y));
      while (x < width) {
        currentType = this.getCell(x, y);
        if (this.getCell(x, y) === this.getCell(x + 1, y)) {
          matchedCells.push(this.getPos(x + 1, y));
        } else {
          if (matchedCells.length >= 3) {
            matches.push({
              index: matchedCells[0],
              horizontal: true,
              count: matchedCells.length,
              type: currentType,
              cells: [...matchedCells],
            });
          }
          matchedCells.length = 0;
          matchedCells.push(this.getPos(x + 1, y));
        }
        x++;
      }
    }

    for (let x = 0; x < width; x++) {
      let y = 0;
      let currentType = undefined;
      matchedCells.length = 0;
      matchedCells.push(this.getPos(x, y));
      while (y < height) {
        currentType = this.getCell(x, y);
        if (this.getCell(x, y) === this.getCell(x, y + 1)) {
          matchedCells.push(this.getPos(x, y + 1));
        } else {
          if (matchedCells.length >= 3) {
            matches.push({
              index: matchedCells[0],
              horizontal: false,
              count: matchedCells.length,
              type: currentType,
              cells: [...matchedCells],
            });
          }
          matchedCells.length = 0;
          matchedCells.push(this.getPos(x, y + 1));
        }
        y++;
      }
    }
    return matches;
  }
  randomize() {
    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        this.state[y * this.width + x] = Math.floor(Math.random() * this.cellTypeCount);
      }
    }
  }
}
