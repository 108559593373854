/* eslint-disable @typescript-eslint/no-explicit-any */
import { Texture } from 'gdxts';
import { GameManager } from '../main';
import { WORLD_HEIGHT, WORLD_WIDTH } from '../Constants';
import gamefoxSDK from '@/utils/gameFoxSDK';
import { emitter } from '../utils/emitter';

const convertToRadians = (angle: number) => {
  return angle * (Math.PI / 180);
};

enum ItemAtAngle {
  'CASH' = 120,
  'IPHONE' = 65,
  'EVOUCHERGOTIT' = 240,
  'GRAFT' = 300,
  'EVOUNCHERNORMAL' = 0,
  'CUP' = 180,
}

export const registerSpinRenderSystem = (manager: GameManager) => {
  const batch = manager.context.batch;
  const assets = manager.context.assets;
  const inputHandler = manager.context.inputHandler;

  const board = assets.getTexture('board') as Texture;
  const spinButton = assets.getTexture('spinButton') as Texture;
  const spinCircle = assets.getTexture('spinCircle') as Texture;
  const playSpinButton = assets.getTexture('playSpinButton') as Texture;
  const spinBannerGame = assets.getTexture('spinBannerGame') as Texture;

  const boardScale = 0.6;
  const spinButtonScale = 0.5;
  const playSpinButtonScale = 0.6;
  const bannerScale = 0.6;
  let hasSpined = false;
  // spin logic
  let spining = false;

  let spinCircleSpeed = 1;
  const spinCircleMaxSpeed = 100;
  let spinCircleAngle = convertToRadians(300); // 0 - 360

  let countSpinTime = 0;
  const spinTime = 3;
  let aimAngle = null;
  let dragging = false;
  let itemTypeData = null;
  const spinResult = (data: any) => {
    if (spining) return;
    spining = true;

    itemTypeData = data?.itemType || null;
    switch (itemTypeData) {
      case 'V_TOPUP_5555' ||
        'V_TOPUP_6666' ||
        'V_TOPUP_8888' ||
        'V_TOPUP_9999' ||
        'V_TOPUP_20K' ||
        'V_TOPUP_50K' ||
        'V_TOPUP_100K':
        aimAngle = ItemAtAngle.EVOUNCHERNORMAL;
        break;
      case 'CASH_5555' || 'CASH_6666' || 'CASH_8888' || 'CASH_9999' || 'CASH_20K' || 'CASH_50K':
        aimAngle = ItemAtAngle.CASH;
        break;
      // case 'EV_'
      //   aimAngle = ItemAtAngle.EVOUCHERGOTIT;
      //   break;
      case 'TY_1' ||
        'TY_2' ||
        'SUU_1' ||
        'SUU_2' ||
        'DAN_1' ||
        'DAN_2' ||
        'MAO_1' ||
        'MAO_2' ||
        'THIN_1' ||
        'THIN_2' ||
        'RAN_1' ||
        'RAN_2' ||
        'NGO_1' ||
        'NGO_2' ||
        'MUI_1' ||
        'MUI_2' ||
        'THAN_1' ||
        'THAN_2' ||
        'DAU_1' ||
        'DAU_2' ||
        'TUAT_1' ||
        'TUAT_2' ||
        'HOI_1' ||
        'HOI_2' ||
        'FINAL_MASCOT':
        aimAngle = ItemAtAngle.GRAFT;
        break;
      case 'IPHONE_15_PRO':
        aimAngle = ItemAtAngle.IPHONE;
        break;
      case 'POINT_666' ||
        'POINT_888' ||
        'POINT_999' ||
        'POINT_1K' ||
        'POINT_2K' ||
        'POINT_3K' ||
        'POINT_5K' ||
        'POINT_10K' ||
        'POINT_20K' ||
        'POINT_50K' ||
        'POINT_100K' ||
        'POINT_200K' ||
        'POINT_500K':
        aimAngle = ItemAtAngle.CUP;
        break;
      default:
        console.log('not found itemType', itemTypeData);
        break;
    }
    console.log('setAngle', aimAngle, itemTypeData);
  };

  emitter.on('spinResult', spinResult);

  manager.addSystem(() => {
    return {
      process(delta: number) {
        if (spinCircleAngle > convertToRadians(360)) {
          spinCircleAngle = 0;
        }
        if (inputHandler.isTouched() && !dragging) {
          dragging = true;
        } else {
          if (!inputHandler.isTouched() && dragging) {
            dragging = false;
          }
        }
        if (spining && countSpinTime <= spinTime) {
          countSpinTime += delta;
          spinCircleSpeed += 0.15;

          if (spinCircleSpeed > spinCircleMaxSpeed) {
            spinCircleSpeed = spinCircleMaxSpeed * delta;
          }
          spinCircleAngle += spinCircleSpeed * delta;
        }

        if (spining && countSpinTime > spinTime && aimAngle !== null) {
          spinCircleAngle += spinCircleSpeed * delta;

          if (spinCircleSpeed > 2) {
            spinCircleSpeed -= 0.1;
          }

          if (
            spinCircleSpeed <= 2 &&
            spinCircleAngle >= convertToRadians(aimAngle - 5) &&
            spinCircleAngle < convertToRadians(aimAngle + 5)
          ) {
            spining = false;
            countSpinTime = 0;
            spinCircleSpeed = 1;
            aimAngle = null;
            setTimeout(() => {
              emitter.emit('showResultPopup');
            }, 1000);
          }
        }

        if (inputHandler.isTouched() && !spining && !hasSpined) {
          const { x, y } = inputHandler.getTouchedWorldCoord();
          if (x > 125 && x < 380 && y > 775 && y < 850) {
            emitter.emit('spin');
            hasSpined = true;
          }
        }
        batch.draw(
          spinBannerGame,
          WORLD_WIDTH / 2 - (spinBannerGame.width / 2) * bannerScale,
          WORLD_HEIGHT / 2 - spinBannerGame.height / 2 - 400,
          spinBannerGame.width * bannerScale,
          spinBannerGame.height * bannerScale,
        );
        batch.draw(
          board,
          WORLD_WIDTH / 2 - (board.width / 2) * boardScale,
          WORLD_HEIGHT / 2 - board.height / 2 + 225,
          board.width * boardScale,
          board.height * boardScale,
        );
        batch.draw(
          spinCircle,
          WORLD_WIDTH / 2 - spinCircle.width / 2,
          WORLD_HEIGHT / 2 - spinCircle.height / 2 - 50,
          spinCircle.width,
          spinCircle.height,
          spinCircle.width / 2,
          spinCircle.height / 2,
          spinCircleAngle,
        );
        batch.draw(
          spinButton,
          WORLD_WIDTH / 2 - (spinButton.width / 2) * spinButtonScale - 5,
          WORLD_HEIGHT / 2 - spinButton.height / 2 + 45,
          spinButton.width * spinButtonScale,
          spinButton.height * spinButtonScale,
        );

        batch.draw(
          playSpinButton,
          WORLD_WIDTH / 2 - (playSpinButton.width / 2) * playSpinButtonScale,
          WORLD_HEIGHT - playSpinButton.height - 100,
          playSpinButton.width * playSpinButtonScale,
          playSpinButton.height * playSpinButtonScale,
        );
      },
      dispose() {
        emitter.off('spinResult', spinResult);
      },
    };
  });
};
