import { FC, useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { useLocation } from '@/hooks/useLocation';
import { useEquipSpirit } from '@/hooks/useEquipSpirit';
import { useStoreState } from '@/hooks/useStore';
import gamefoxSDK from '@/utils/gameFoxSDK';
import { useRefreshUser } from '@/hooks/useRefreshUser';

type Props = {};

const ownedSpirit = [
  'rat',
  'ox',
  'tiger',
  'cat',
  'dragon',
  'snake',
  'horse',
  'goat',
  'monkey',
  'chicken',
  'dog',
  'pig',
];

export const SelectSpiritScreen: FC<Props> = () => {
  const { setLocation } = useLocation();
  const equipSpirit = useEquipSpirit();
  const user = useStoreState((state) => state.user);

  const [selected, setSelected] = useState<string[]>([]);
  const refreshUser = useRefreshUser();
  const inventory = useStoreState((state) => state.inventory);

  useEffect(() => {
    if (user?.data.sh2Mascot) {
      setSelected(user.data.sh2Mascot);
    }
  }, [user.data.sh2Mascot]);

  const handleSubmitSpirit = useCallback(
    async (spirits: string[]) => {
      try {
        const isSuccess = await equipSpirit(spirits);

        if (!isSuccess) {
          // show error;
        } else {
          if (inventory.progressTickets.length) {
            await refreshUser();
            setLocation('/game');

            await gamefoxSDK.startProgressTicket(inventory.progressTickets[0].id);
          }
        }
      } catch (error) {
        // show error popup
      }
    },
    [equipSpirit, inventory.progressTickets, refreshUser, setLocation],
  );

  const handleSelectSpirit = useCallback(
    (spirit: string) => {
      if (selected.length === 4) return;

      const isSelected = selected.includes(spirit);

      if (isSelected) {
        setSelected(selected.filter((item) => item !== spirit));
      } else {
        const newSelected = [...selected, spirit];
        setSelected(newSelected);

        if (newSelected.length === 4) {
          handleSubmitSpirit(newSelected);
        }
      }
    },
    [handleSubmitSpirit, selected],
  );

  const handleUnselectSpirit = useCallback(
    (spirit: string) => {
      setSelected(selected.filter((item) => item !== spirit));
    },
    [selected],
  );

  const handleRandomSelect = useCallback(() => {
    const randomSpirits: string[] = [];
    const copyOfOwnedSpirit = [...ownedSpirit];

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * copyOfOwnedSpirit.length);
      randomSpirits.push(copyOfOwnedSpirit[randomIndex]);
      copyOfOwnedSpirit.splice(randomIndex, 1);
    }

    setSelected(randomSpirits);
    handleSubmitSpirit(randomSpirits);
  }, [handleSubmitSpirit]);

  return (
    <div className="w-full h-full flex flex-col items-center relative overflow-y-auto">
      <img className="w-1/3 h-auto mt-4" src="/images/logo.png" alt="" />
      <div className="flex flex-col items-center justify-center w-full ms:h-16 mm:h-20 bg-select-spirit-notice-bar bg-no-repeat bg-center bg-contain">
        <p className="text-orange-80 font-bold mm:text-sm ms:text-ms">Chọn linh thú để</p>
        <p className="text-orange-80 font-bold mm:text-sm ms:text-ms">đồng hành</p>
      </div>

      <div className="w-full md:w-2/3 grid grid-cols-4 gap-1 pl-2 pr-2 mt-5">
        {selected.map((spirit, index) => {
          const assetUrl = `/images/${spirit}_with_gem.png`;

          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: 'easeIn' }}
              onClick={() => handleUnselectSpirit(spirit)}
              key={index}
              className="w-full relative cursor-pointer hover:opacity-50"
            >
              <img className="w-full h-auto" src={assetUrl} alt="" />
            </motion.div>
          );
        })}

        {Array.from({ length: 4 - selected.length }).map((_, index) => {
          return (
            <div key={index} className="w-full relative">
              <img className="w-full h-auto" src="/images/slot_lighting_blue.png" alt="" />
            </div>
          );
        })}
      </div>

      <div className="w-full md:w-2/3 grid grid-cols-4 grid-rows-3 gap-1 rounded-3xl pr-1 pb-5 pt-5 shadow-around mt-5">
        {ownedSpirit.map((spirit, index) => {
          let assetUrl = `/images/${spirit}_with_gem.png`;

          const isSelected = selected.includes(spirit);

          if (isSelected) {
            assetUrl = `/images/slot_lighting_orange.png`;
          }

          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: 'easeIn' }}
              onClick={() => handleSelectSpirit(spirit)}
              key={index}
              className="w-full relative cursor-pointer hover:opacity-50"
            >
              <img className="w-full h-auto" src={assetUrl} alt="" />
            </motion.div>
          );
        })}
      </div>
      <div className="flex items-center justify-center cursor-pointer hover:opacity-80 mt-5">
        <img onClick={handleRandomSelect} className="w-1/3 h-auto sm:w-2/3" src="/images/button_random.png" alt="" />
      </div>
    </div>
  );
};
