import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.css';

import gamefoxSDK, { Pool } from '@/utils/gameFoxSDK';
import { useLocation } from '@/hooks/useLocation';
import { useStoreState, useStoreActions } from '@/hooks/useStore';
import { usePopup } from '@/hooks/usePopup';

type Props = {};

enum Tab {
  BAG = 1,
  STORAGE = 2,
}

export const RewardScreen: FC<Props> = () => {
  const { setLocation } = useLocation();

  const inventory = useStoreState((state) => state?.inventory);
  const setIphoneClaimDate = useStoreActions((actions) => actions.setIphoneClaimDate);
  const { setIphonePopupVisible } = usePopup();

  const [tab, setTab] = useState<Tab>(Tab.BAG);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pool, setPool] = useState<Pool[]>([]);

  const tabBackground = useMemo(() => {
    switch (tab) {
      case Tab.BAG:
        return 'bg-reward-tab-1';
      case Tab.STORAGE:
        return 'bg-reward-tab-2';
    }
  }, [tab]);

  const generateRewardInfo = useCallback((type: string) => {
    let value;
    let rewardName;

    switch (type) {
      case 'IPHONE_15_PRO':
        return ['/images/reward_iphone.png', 'Iphone 15 Pro'];
      case 'CASH_868':
      case 'CASH_888':
      case 'CASH_999':
      case 'CASH_5555':
      case 'CASH_6666':
      case 'CASH_8888':
      case 'CASH_9999':
      case 'CASH_20K':
      case 'CASH_50K':
        value = type.replace('CASH_', '');

        if (value.includes('K')) {
          value = value.replace('K', ',000');
        }

        rewardName = `Tiền mặt ${value} VND`;
        return ['/images/reward_cash.png', rewardName];
      case 'cash_voucher_1':
      case 'cash_voucher_2':
      case 'cash_voucher_3':
      case 'cash_voucher_4':
        return ['/images/reward_cash_voucher.png', ''];
      case 'V_TOPUP_5555':
      case 'V_TOPUP_6666':
      case 'V_TOPUP_8888':
      case 'V_TOPUP_9999':
      case 'V_TOPUP_20K':
      case 'V_TOPUP_50K':
        value = type.replace('V_TOPUP_', '');

        if (value.includes('K')) {
          value = value.replace('K', ',000');
        }
        rewardName = `Voucher ${value} VND`;
        return ['/images/reward_voucher.png', rewardName];
      case 'V_GOTIT':
        return ['/images/reward_gotit.png', 'Voucher Gotit'];
      case 'POINT_888':
      case 'POINT_999':
      case 'POINT_666':
      case 'POINT_1K':
      case 'POINT_2K':
      case 'POINT_3K':
      case 'POINT_5K':
      case 'POINT_10K':
      case 'POINT_20K':
      case 'POINT_30K':
      case 'POINT_40K':
      case 'POINT_50K':
      case 'POINT_100K':
      case 'POINT_200K':
      case 'POINT_500K':
        value = type.replace('POINT_', '');

        if (value.includes('K')) {
          value = value.replace('K', ',000');
        }
        rewardName = `${value} Điểm SHB REWARD`;
        return ['/images/reward_point.png', rewardName];
    }
  }, []);

  const handleTriggerRewardAction = useCallback(
    (item) => {
      if (item.type === 'IPHONE_15_PRO') {
        setIphoneClaimDate(dayjs(item.createdAt).format('DD/MM/YYYY'));
        setIphonePopupVisible(true);
      }

      if (item.type === 'V_GOTIT') {
        console.log('show gotit popup');
      }
    },
    [setIphoneClaimDate, setIphonePopupVisible],
  );

  const generateRewardAction = useCallback(
    (item) => {
      let actionButtonImage = '';

      switch (item.type) {
        case 'IPHONE_15_PRO':
        case 'V_GOTIT':
          actionButtonImage = '/images/action_detail.png';
          break;
      }

      if (item.type.startsWith('POINT_')) {
        actionButtonImage = '/images/action_explore.png';
      }

      if (item.type.startsWith('V_TOPUP_')) {
        actionButtonImage = '/images/action_use.png';
      }

      if (item.type.startsWith('CASH_')) {
        actionButtonImage = '/images/action_claim.png';
      }

      if (!actionButtonImage) return null;

      return (
        <img
          onClick={() => handleTriggerRewardAction(item)}
          className="w-auto ms:h-8 ml:h-10 cursor-pointer hover:opacity-50"
          src={actionButtonImage}
          alt=""
        />
      );
    },
    [handleTriggerRewardAction],
  );

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const pool = await gamefoxSDK.getPools();

      setPool(pool);
    } catch (error) {
      // TODO show error popup
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="w-full h-full flex flex-1 flex-col items-center overflow-y-hidden">
      <div className="text-lg text-blue-90 font-bold">Phần thưởng</div>
      <div
        className={`w-full flex flex-col items-center ${styles.container} ${tabBackground} bg-no-repeat bg-cover bg-top pr-3 pl-3`}
      >
        <div className={`w-full flex ${styles.tabSeletor}`}>
          <div onClick={() => setTab(Tab.BAG)} className="flex-1 p-2 cursor-pointer" />
          <div onClick={() => setTab(Tab.STORAGE)} className="flex-1 p-2 cursor-pointer" />
        </div>
        <div className={`w-full overflow-y-auto mt-4 ${styles.tabContent}`}>
          {isLoading ? (
            <p className="text-base text-orange-80 text-center font-bold">Chờ chút nhé</p>
          ) : (
            <>
              {tab === Tab.BAG && (
                <>
                  {inventory?.items?.length ? (
                    <>
                      {(inventory?.items || []).map((inventoryItem, index) => {
                        return (
                          <div
                            className={`w-full flex bg-reward-item-background bg-no-repeat bg-contain ${styles.rewardItem} mb-1`}
                          >
                            <div className={`${styles.rewardImgContainer} flex justify-center items-center`}>
                              <img className="w-auto h-2/3" src={generateRewardInfo(inventoryItem.type)[0]} alt="" />
                            </div>
                            <div key={index} className="flex-1 flex flex-col justify-center pr-2 pl-2">
                              <p className="text-base leading-4 text-blue-90 font-bold">
                                {generateRewardInfo(inventoryItem.type)[1]}
                              </p>
                              <p className="text-sm text-orange-80 font-semibold">
                                Ngày nhận: {dayjs(inventoryItem.createdAt).format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className="flex items-center justify-center pr-1">
                              {generateRewardAction(inventoryItem)}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <p className="text-base text-orange-80 text-center font-bold">
                      Bạn chưa có phần thưởng nào trong giỏ quà
                    </p>
                  )}
                </>
              )}

              {tab === Tab.STORAGE && (
                <>
                  {pool.map((poolItem, index) => {
                    if (['LUCKY_WISH', 'FINAL_MASCOT'].includes(poolItem.itemType)) return null;

                    return (
                      <div
                        className={`w-full flex bg-reward-item-background bg-no-repeat bg-contain ${styles.rewardItem} mb-1`}
                      >
                        <div className={`${styles.rewardImgContainer} flex justify-center items-center`}>
                          <img className="w-auto h-2/3" src={generateRewardInfo(poolItem.itemType)?.[0]} alt="" />
                        </div>
                        <div key={index} className="flex-1 flex flex-col justify-center pr-2 pl-2">
                          <p className="text-base text-blue-90 font-bold">
                            {generateRewardInfo(poolItem.itemType)?.[1]}
                          </p>
                          <p className="text-sm text-orange-80 font-semibold">
                            Số lượng còn lại: {poolItem.remaining || 0}/{poolItem.workingTimeAmount || 0}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <img
        onClick={() => setLocation('/main')}
        className="w-1/2 md:w-1/3 h-auto mt-3 mb-3 cursor-pointer hover:opacity-50"
        src="/images/button_main.png"
        alt=""
      />
    </div>
  );
};
