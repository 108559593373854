import { FC } from 'react';

import { useLocation } from '@/hooks/useLocation';

import { LevelSelector } from '@/components/LevelSelector';

type Props = {};

export const LevelScreen: FC<Props> = () => {
  const { setLocation } = useLocation();

  return (
    <div className="w-full h-full flex flex-col items-center overflow-y-auto">
      <div className="bg-level-notice-bar bg-no-repeat bg-contain w-full h-20 flex flex-col items-center justify-center mt-10">
        <p className="text-sm text-orange-80 font-bold">Bạn có thể thu thập mảnh ghép tại màn chơi</p>
        <p className="text-sm text-orange-80 font-bold">tương ứng với linh thú đã chọn</p>
        <p className="text-lg text-blue-90 font-bold">có 95 lượt chơi</p>
      </div>
      <LevelSelector />
      <div className="w-full flex items-center justify-center mt-20 mb-20">
        <div
          onClick={() => setLocation('/select-spirit')}
          className="flex flex-1 items-center justify-center pl-4 pr-2 cursor-pointer hover:opacity-80"
        >
          <img className="w-full h-auto sm:w-2/3" src="/images/button_spirit.png" alt="" />
        </div>
        <div className="flex flex-1 items-center justify-center pl-2 pr-4 cursor-pointer hover:opacity-80">
          <img className="w-full h-auto sm:w-2/3" src="/images/button_collection_2.png" alt="" />
        </div>
      </div>
    </div>
  );
};
