import { FC } from 'react';
import styles from './styles.module.css';

import { usePopup } from '@/hooks/usePopup';
import { useStoreState } from '@/hooks/useStore';
import navigate from '@/utils/navigateFunction';

type Props = {
  isVisible: boolean;
};

export const IphonePopup: FC<Props> = ({ isVisible }) => {
  const { setIphonePopupVisible } = usePopup();
  const iphoneClaimDate = useStoreState((state) => state?.iphoneClaimDate);

  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <div style={{ width: '90%' }} className="max-w-screen-ml flex flex-col items-center justify-center relative">
          <div className="absolute -top-14 right-0 cursor-pointer hover:opacity-50 z-30">
            <img
              onClick={() => setIphonePopupVisible(false)}
              className="h-6 w-6"
              src="/images/popup_scroll_button_close.png"
              alt=""
            />
          </div>
          <div className={`flex flex-col absolute z-10 w-2/3 ${styles.content}`}>
            <p className="text-sm text-blue-90 font-bold mb-4">Ngày trúng giải: {iphoneClaimDate}</p>
            <p className="text-sm text-orange-80">
              Bạn vui lòng chọn “Liên hệ ngay" để đọc chi tiết cách thức lấy mã voucher nhận thưởng iPhone
            </p>
          </div>
          <div
            className={`flex flex-1 items-center justify-center cursor-pointer hover:opacity-80 z-10 absolute ${styles.button}`}
          >
            <img
              onClick={() => navigate.navigateContact()}
              className="w-auto h-full sm:w-2/3"
              src="/images/button_contact.png"
              alt=""
            />
          </div>
          <img src="/images/popup_iphone.png" alt="" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};
