import { useCallback } from 'react';

import gamefoxSDK from '@/utils/gameFoxSDK';
import { useStoreActions } from '@/hooks/useStore';

export const useRefreshInventory = () => {
  const setInventory = useStoreActions((actions) => actions.setInventory);

  const fetchInventory = useCallback(async () => {
    const sdkParams = gamefoxSDK.getParams();
    const inventory = await gamefoxSDK.getInventory(sdkParams.campaignId, 'CAMPAIGN');

    setInventory(inventory);
  }, [setInventory]);

  return fetchInventory;
};
