import { useCallback } from 'react';

import gamefoxSDK from '@/utils/gameFoxSDK';
import { useStoreActions } from '@/hooks/useStore';

export const useEquipSpirit = () => {
  const setSpirit = useStoreActions((actions) => actions.setEquipedSpirit);

  const equipSpirit = useCallback(
    async (spirits: string[]) => {
      const data = await gamefoxSDK.equipMascots(spirits);

      if (data) {
        setSpirit(data);
        return true;
      } else {
        return false;
      }
    },
    [setSpirit],
  );

  return equipSpirit;
};
