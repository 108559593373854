/* eslint-disable @typescript-eslint/no-explicit-any */
import { MultiTextureBatch, ViewportInputHandler, createGameLoop, createViewport } from 'gdxts';
import { getAssets } from './Assets';
import { WORLD_WIDTH, WORLD_HEIGHT } from './Constants';
import { Manager } from './system-manager';
import { registerSpinRenderSystem } from './system-manager/spinSystem';

export const spin = async (canvas: HTMLCanvasElement) => {
  const viewport = createViewport(canvas, WORLD_WIDTH, WORLD_HEIGHT, {
    crop: false,
  });

  const gl = viewport.getContext();
  const camera = viewport.getCamera();
  camera.setYDown(true);

  const assets = getAssets(gl);

  await assets.finishLoading();

  const batch = new MultiTextureBatch(gl);
  batch.setYDown(true);

  const inputHandler = new ViewportInputHandler(viewport);

  const manager = new Manager()
    .register('camera', camera)
    .register('viewport', viewport)
    .register('gl', gl)
    .register('assets', assets)
    .register('batch', batch)
    .register('inputHandler', inputHandler);

  gl.clearColor(0, 0, 0, 0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  registerSpinRenderSystem(manager);

  const loop = createGameLoop((delta: number) => {
    gl.clear(gl.COLOR_BUFFER_BIT);

    batch.setProjection(camera.combined);
    batch.begin();

    manager.process(delta);
    batch.end();
  });

  return {
    manager,
    dispose() {
      loop.stop();
      batch.dispose();
      assets.dispose();
      manager.dispose();
      inputHandler.cleanup();
    },
  };
};

export type GameManager = Awaited<ReturnType<typeof spin>>['manager'];
