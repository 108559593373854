import { createTypedHooks } from 'easy-peasy';

import { StoreModel } from '@/utils/store';

const typedHooks = createTypedHooks<StoreModel>();

const useStoreActions = typedHooks.useStoreActions;
const useStoreState = typedHooks.useStoreState;

export { useStoreActions };
export { useStoreState };
