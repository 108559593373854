/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';

export type MessageEvents = {
  matches: (count: number, type: number) => void;
  finish: (victory: boolean, score: number) => void;
  spin: () => void;
  spinResult: (data: any) => void;
  showResultPopup: () => void;
  detectSwap: () => void;

  finishedLoading: () => void;
};

export const emitter = new EventEmitter() as TypedEmitter<MessageEvents>;
