import { FC, useEffect } from 'react';
import { motion } from 'framer-motion';

type Props = {
  onFinished?: () => void;
};

export const LoadingBar: FC<Props> = ({ onFinished }) => {
  useEffect(() => {
    setTimeout(() => {
      onFinished?.();
    }, 5000);
  }, [onFinished]);

  return (
    <div className="w-full flex flex-col items-center">
      <p className="text-orange-80 font-bold mb-2">Chờ chút nhé</p>
      <div className="w-full bg-white h-3 rounded-md">
        <motion.div
          initial={{ width: '0%' }}
          animate={{ width: ['0%', '20%', '40%', '60%', '80%', '100%'] }}
          transition={{
            duration: 5,
            times: [0, 0.2, 0.4, 0.6, 0.8, 1],
            ease: 'linear',
            repeat: 0,
          }}
          className="h-full bg-orange-brand rounded-md"
        />
      </div>
    </div>
  );
};
