import { FC, useCallback, useEffect } from 'react';

import { useLocation } from '@/hooks/useLocation';
import { useStoreState } from '@/hooks/useStore';
import { usePopup } from '@/hooks/usePopup';
import { useRefreshInventory } from '@/hooks/useRefreshInventory';
import gamefoxSDK from '@/utils/gameFoxSDK';
import navigate from '@/utils/navigateFunction';

import { Checkin } from '@/components/Checkin';
import { Energy } from '@/components/Energy';

type Props = {};

export const MainScreen: FC<Props> = () => {
  const { setLocation } = useLocation();
  const { setOutOfTurnPopupVisible } = usePopup();
  const user = useStoreState((state) => state?.user);
  const inventory = useStoreState((state) => state?.inventory);
  const refreshInventory = useRefreshInventory();

  const handlePLay = useCallback(async () => {
    if (!inventory?.progressTickets.length) {
      setOutOfTurnPopupVisible(true);
      return;
    }

    if (!user.data.sh2Mascot) {
      setLocation('/select-spirit');
      return;
    } else {
      await refreshInventory();
      await gamefoxSDK.startProgressTicket(inventory.progressTickets[0].id);
      setLocation('/game');
    }
  }, [inventory?.progressTickets, refreshInventory, setLocation, setOutOfTurnPopupVisible, user.data.sh2Mascot]);

  useEffect(() => {
    if (user?.progressData.winCount === 6) {
      setLocation('/spin');
    }
  }, [setLocation, user]);

  return (
    <div className="w-full h-full flex flex-col items-center relative overflow-y-auto">
      <img onClick={() => navigate.navigateExitGame()} className="w-1/3 h-auto mt-4" src="/images/logo.png" alt="" />
      <div className="md:w-20 ms:w-16 flex items-center justify-center absolute top-0 right-0">
        <img
          onClick={() => navigate.navigateShareGame('', '')}
          className="md:h-12 md:w-12 ms:h-10 cursor-pointer hover:opacity-80"
          src="/images/button_share.png"
          alt=""
        />
      </div>
      <Checkin />
      <Energy turns={inventory?.progressTickets.length} energyLevel={user?.progressData.winCount} />
      <div onClick={() => handlePLay()} className="mt-3 ml:mt-4 cursor-pointer hover:opacity-80">
        <img className="w-auto md:h-20 ms:h-10 mm:h-16" src="images/button_play.png" alt="" />
      </div>
      <div onClick={() => setLocation('/reward')} className="mt-3 ml:mt-4 cursor-pointer hover:opacity-80">
        <img className="w-auto md:h-20 ms:h-10 mm:h-16" src="images/button_reward.png" alt="" />
      </div>
      <div onClick={() => setLocation('/missions')} className="mt-3 ml:mt-4 cursor-pointer hover:opacity-80">
        <img className="w-auto md:h-20 ms:h-10 mm:h-16" src="images/button_mission.png" alt="" />
      </div>
      <div onClick={() => setLocation('/collection')} className="mt-3 ml:mt-4 cursor-pointer hover:opacity-80">
        <img className="w-auto md:h-20 ms:h-10 mm:h-16" src="images/button_collection.png" alt="" />
      </div>

      <div className="flex flex-col items-center mt-20 mb-5">
        <div className="flex items-center">
          <div className="h-5 w-5 rounded-full border-orange-brand border-2 mb-1 mr-2 bg-white cursor-pointer hover:opacity-50"></div>
          <p className="text-blue-90 font-bold text-sm">Tôi đồng ý với thể lệ chương trình</p>
        </div>
        <p className="underline text-blue-90 text-sm cursor-pointer hover:opacity-50">Xem lại hướng dẫn</p>
      </div>
    </div>
  );
};
