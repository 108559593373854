import { FC } from 'react';

type Props = {};

export const LevelSelector: FC<Props> = () => {
  return (
    <div className="w-3/4 sm:w-1/2 flex flex-col items-center justify-center mm:mt-10 ms:mt-5">
      <div className="w-full inset-0 flex items-center justify-center relative">
        <img className="w-full h-auto" src="/images/shield.png" alt="" />
        <div className="absolute h-auto w-1/2">
          <img className="h-full w-full" src="/images/saha_energy_2.png" alt="" />
        </div>
        {/* tiger */}
        <div className="absolute top-0 translate-x-10 -translate-y-2 sm:translate-x-16">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/tiger_active.png" alt="" />
        </div>
        {/* cat */}
        <div className="absolute top-0 right-0 translate-x-2 translate-y-8">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/cat_active.png" alt="" />
        </div>
        {/* dragon */}
        <div className="absolute top-0 right-0 translate-x-6 translate-y-28 sm:translate-y-32">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/dragon_active.png" alt="" />
        </div>
        {/* snake */}
        <div className="absolute right-0 translate-x-4 translate-y-1/2 sm:translate-y-1/3">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/snake_active.png" alt="" />
        </div>
        {/* horse */}
        <div className="absolute bottom-0 right-0 -translate-x-4 -translate-y-14 sm:-translate-y-20">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/horse_active.png" alt="" />
        </div>
        {/* goat */}
        <div className="absolute bottom-0 translate-x-10 sm:translate-x-16">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/goat_active.png" alt="" />
        </div>
        {/* monkey */}
        <div className="absolute bottom-0 -translate-x-10 sm:-translate-x-16">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/monkey_active.png" alt="" />
        </div>
        {/* chicken */}
        <div className="absolute bottom-0 left-0 translate-x-4 -translate-y-14 sm:-translate-y-20">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/ox_disabled.png" alt="" />
        </div>
        {/* dog */}
        <div className="absolute left-0 -translate-x-4 translate-y-1/2 sm:translate-y-1/3">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/dog_disabled.png" alt="" />
        </div>
        {/* pig */}
        <div className="absolute top-0 left-0 -translate-x-6 translate-y-28">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/pig_active.png" alt="" />
        </div>
        {/* rat */}
        <div className="absolute top-0 left-0 -translate-x-2 translate-y-8">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/rat_active.png" alt="" />
        </div>
        {/* ox */}
        <div className="absolute top-0 -translate-x-10 sm:-translate-x-16">
          <img className="ml:w-16 mm:w-14 ms:w-12 sm:w-20 h-auto" src="/images/ox_active.png" alt="" />
        </div>
      </div>
    </div>
  );
};
