import { useCallback } from 'react';

import gamefoxSDK from '@/utils/gameFoxSDK';
import { useStoreActions } from '@/hooks/useStore';

export const useCheckin = () => {
  const setCheckin = useStoreActions((actions) => actions.setCheckin);

  const fetchCheckinData = useCallback(async () => {
    const checkin = await gamefoxSDK.getCheckin();

    !!checkin && setCheckin(checkin);
  }, [setCheckin]);

  return fetchCheckinData;
};
