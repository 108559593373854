import { AssetManagerBase, Downloader } from './spine-core';
import { Texture } from 'gdxts';

export class SpineAssetManager extends AssetManagerBase {
  constructor(context: WebGLRenderingContext, pathPrefix: string = '', downloader: Downloader = new Downloader()) {
    super(
      (image: HTMLImageElement | ImageBitmap) => {
        return new Texture(context, image);
      },
      pathPrefix,
      downloader
    );
  }
}
