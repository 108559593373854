import { spin } from '@/GameSystem/spin';
import { emitter } from '@/GameSystem/utils/emitter';
import { usePopup } from '@/hooks/usePopup';
import gamefoxSDK from '@/utils/gameFoxSDK';
import { FC, useEffect, useRef, useState } from 'react';

import { useStoreActions } from '@/hooks/useStore';

type Props = {};

export const SpinScreen: FC<Props> = () => {
  const ref = useRef<HTMLCanvasElement>(null);
  const { setLuckySpinPopupVisible } = usePopup();
  const [hasSpined, setHasSpined] = useState(false);

  const setGiftData = useStoreActions((actions) => actions.setGiftData);

  useEffect(() => {
    if (!ref.current) return;
    const gamePromise = spin(ref.current).then(async (game) => {
      return game;
    });
    return () => {
      gamePromise.then((game) => game.dispose());
    };
  }, []);

  useEffect(() => {
    const handleSpin = async () => {
      if (hasSpined) return;
      const spinResult = await gamefoxSDK.useSpin('PLAY_GAME');
      setGiftData(spinResult);
      emitter.emit('spinResult', spinResult);
      setHasSpined(true);
    };

    emitter.on('spin', handleSpin);
    return () => {
      emitter.off('spin', handleSpin);
    };
  }, [hasSpined, setGiftData]);

  useEffect(() => {
    const showResultPopup = () => {
      setLuckySpinPopupVisible(true);
    };
    emitter.on('showResultPopup', showResultPopup);
    return () => {
      emitter.off('showResultPopup', showResultPopup);
    };
  }, [setLuckySpinPopupVisible]);

  return (
    <div className="w-full h-full flex flex-1 flex-col items-center overflow-y-hidden">
      <div className="absolute w-full h-full z-10">{<canvas className="w-full h-full" ref={ref} />}</div>
    </div>
  );
};
