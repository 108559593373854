import { Assets } from './utils/assets';

export const getAssets = (gl: WebGLRenderingContext) => {
  const assets = new Assets(gl)
    .font('exoBold', 'fonts/ExoBold.fnt')
    .texture('gem1', 'images/gem1.png')
    .texture('gem2', 'images/gem2.png')
    .texture('gem3', 'images/gem3.png')
    .texture('gem4', 'images/gem4.png')
    .texture('gem5', 'images/gem5.png')
    .texture('gem6', 'images/gem6.png')
    .texture('gem7', 'images/gem7.png')
    .texture('gem8', 'images/gem8.png')
    .texture('gem9', 'images/gem9.png')
    .texture('gem10', 'images/gem10.png')
    .texture('gem11', 'images/gem11.png')
    .texture('gem12', 'images/gem12.png')
    .texture('block', 'images/block.png')
    .texture('characterGem1', 'images/characterGem1.png')
    .texture('characterGem2', 'images/characterGem2.png')
    .texture('characterGem3', 'images/characterGem3.png')
    .texture('characterGem4', 'images/characterGem4.png')
    .texture('characterGem5', 'images/characterGem5.png')
    .texture('characterGem6', 'images/characterGem6.png')
    .texture('characterGem7', 'images/characterGem7.png')
    .texture('characterGem8', 'images/characterGem8.png')
    .texture('characterGem9', 'images/characterGem9.png')
    .texture('characterGem10', 'images/characterGem10.png')
    .texture('characterGem11', 'images/characterGem11.png')
    .texture('characterGem12', 'images/characterGem12.png')
    .texture('boss1', 'images/boss1.png')
    .texture('spinButton', 'images/spinButton.png')
    .texture('spinCircle', 'images/spinCircle.png')
    .texture('playSpinButton', 'images/playSpinButton.png')
    .texture('board', 'images/board.png')
    .texture('border', 'images/border.png')
    .texture('borderFull', 'images/borderFull.png')
    .texture('borderTop', 'images/borderTop.png')
    .texture('borderBottom', 'images/borderBottom.png')
    .texture('borderLeft', 'images/borderLeft.png')
    .texture('borderRight', 'images/borderRight.png')
    .texture('spinBannerGame', 'images/spinBannerGame.png')
    .texture('turn_play_board', 'images/turn_play_board.png');
  return assets;
};
