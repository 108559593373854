import { useEffect, useState } from 'react';
import { Router, Route } from 'wouter';
import { StoreProvider } from 'easy-peasy';

import initSdk from '@/utils/initSdk';
import store from '@/utils/store';

import { Layout } from '@/components/layout';
import { PopupProvider } from '@/components/Popup';

import { LoadingScreen } from '@/screens/loading';
import { MainScreen } from '@/screens/main';
import { LevelScreen } from '@/screens/level';
import { SelectSpiritScreen } from '@/screens/selectSpirit';
import { GameScreen } from '@/screens/game';
import { TermScreen } from '@/screens/term';
import { RewardScreen } from '@/screens/reward';
import { MissionScreen } from '@/screens/missions';
import { CollectionScreen } from '@/screens/collection';
import { SpinScreen } from './screens/spin';

const WrappedApp = () => {
  const [inited, setInited] = useState(false);

  useEffect(() => {
    (async () => {
      if (!inited) {
        const isSucceess = await initSdk();

        if (isSucceess) {
          setInited(true);
        }
      }
    })();
  }, [inited]);

  if (!inited) {
    return null;
  }

  return <App />;
};

const App = () => {
  return (
    <Router>
      <StoreProvider store={store}>
        <Layout>
          <PopupProvider>
            <Route path="/" component={LoadingScreen} />
            <Route path="/main" component={MainScreen} />
            <Route path="/level" component={LevelScreen} />
            <Route path="/select-spirit" component={SelectSpiritScreen} />
            <Route path="/game" component={GameScreen} />
            <Route path="/term" component={TermScreen} />
            <Route path="/reward" component={RewardScreen} />
            <Route path="/missions" component={MissionScreen} />
            <Route path="/collection" component={CollectionScreen} />
            <Route path="/spin" component={SpinScreen} />
          </PopupProvider>
        </Layout>
      </StoreProvider>
    </Router>
  );
};

export default WrappedApp;
