import { FC } from 'react';

import { usePopup } from '@/hooks/usePopup';
import { useLocation } from '@/hooks/useLocation';

type Props = {
  isVisible: boolean;
};

export const OutOfTurnPopup: FC<Props> = ({ isVisible }) => {
  const { setLocation } = useLocation();
  const { setOutOfTurnPopupVisible } = usePopup();

  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <div
          style={{ width: '80%', height: '50%' }}
          className="max-w-screen-ml flex flex-col items-center justify-center relative"
        >
          <div className="absolute top-0 -right-1 cursor-pointer hover:opacity-50 z-30">
            <img
              onClick={() => setOutOfTurnPopupVisible(false)}
              className="h-6 w-6"
              src="/images/popup_scroll_button_close.png"
              alt=""
            />
          </div>
          <img className="w-1/2 mb-4 z-10" src="/images/gold_sword.png" alt="" />
          <p className="text-sm ml:text-base text-red font-bold z-10">Bạn hết lượt rồi</p>
          <p className="text-sm ml:text-base text-red font-bold z-10">Hãy làm thêm nhiệm vụ nhé</p>
          <div className="flex flex-1 items-center justify-center cursor-pointer hover:opacity-80 z-10 absolute bottom-3">
            <img
              onClick={() => {
                setOutOfTurnPopupVisible(false);
                setLocation('/missions');
              }}
              className="w-3/5 h-auto sm:w-2/3"
              src="/images/button_mission.png"
              alt=""
            />
          </div>
          <img src="/images/popup_scroll.png" alt="" className="w-full h-auto absolute inset-0" />
        </div>
      </div>
    </div>
  );
};
