import { FC } from 'react';

import { useStoreState } from '@/hooks/useStore';

type Props = {};

const data = [
  {
    title: 'Ngày 1',
    day: 1,
  },
  {
    title: 'Ngày 2',
    day: 2,
  },
  {
    title: 'Ngày 3',
    day: 3,
  },
  {
    title: 'Ngày 4',
    day: 4,
  },
  {
    title: 'Ngày 5',
    day: 5,
  },
  {
    title: 'Ngày 6',
    day: 6,
  },
  {
    title: 'Ngày 1',
    day: 7,
  },
];

export const Checkin: FC<Props> = () => {
  const checkin = useStoreState((state) => state.checkin);

  return (
    <div className="w-full md:h-20 ms:h-16 ml:h-20 flex items-center justify-center bg-checkin-bar bg-no-repeat bg-cover mt-4">
      <div className="grid grid-cols-7 md:gap-3 ms:gap-1 mm:gap-2">
        {data.map((item, index) => {
          const isChecked = item.day <= checkin?.loginStreak;

          if (item.day === 7) {
            return (
              <img
                key={index}
                className="md:h-16 md:w-14 ms:h-12 ms:w-10 ml:h-14 ml:w-12"
                src="/images/checkin_reward.png"
                alt=""
              />
            );
          }
          return (
            <div
              key={index}
              className={`md:h-16 md:w-14 ms:h-12 ms:w-10 ml:h-14 ml:w-12 rounded-lg relative ${
                isChecked ? 'bg-no-repeat bg-gradient-to-r from-blue-2 to-blue-3 via-blue-1' : 'bg-gray-400'
              }`}
            >
              <div
                style={{ inset: 1.5 }}
                className="flex flex-col items-center pt-2 ms:pt-1 pb-2 ms:pb-1 absolute rounded-md bg-white"
              >
                <p
                  className={`md:text-xs ms:text-ms ml:text-xs font-semibold ${
                    isChecked ? 'text-blue-90' : 'text-black'
                  }`}
                >
                  {item.title}
                </p>
                <img
                  className="md:h-6 md:w-6 ms:h-4 ms:w-4 ml:h-5 ml:w-5 mt-1"
                  src={isChecked ? '/images/checkin_checked.png' : '/images/checkin_uncheck.png'}
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
