import { GameManager } from '../main';
import { emitter } from '../utils/emitter';

export const registerHandleGameSystem = (manager: GameManager) => {
  const bossCharacterConfig = manager.context.bossCharacterConfig;
  const gameConfig = manager.context.gameConfig;
  const rosterType = manager.context.ROSTER_TYPES;

  let currentHP = bossCharacterConfig.health;
  let gameEnd = manager.context.gameEnd;
  const handleDmgBoss = (count: number, type: number) => {
    if (gameEnd) return;
    if (currentHP - count * rosterType[type].atk <= 0) {
      currentHP = 0;
      gameEnd = true;
    }
    setTimeout(() => {
      if (!rosterType[type]) return;
      const atk = rosterType[type].atk;

      const bossHealth = bossCharacterConfig.health - count * atk;
      bossCharacterConfig.health = bossHealth < 0 ? 0 : bossHealth;
      if (bossCharacterConfig.health <= 0) {
        setTimeout(() => {
          emitter.emit('finish', true, gameConfig.turnPlay);
        }, 2000);
        gameEnd = true;
        return;
      }
    }, 500);
  };

  emitter.on('matches', handleDmgBoss);

  const subTurnPlay = () => {
    if (gameEnd) return;
    gameConfig.turnPlay--;
  };

  emitter.on('detectSwap', subTurnPlay);

  manager.addSystem(() => {
    return {
      process() {
        if (!gameConfig.turnPlay && !gameEnd) {
          if (bossCharacterConfig.health > 0) {
            setTimeout(() => {
              emitter.emit('finish', false, gameConfig.turnPlay);
            }, 3000);
            gameEnd = true;
          }
        }
      },
      dispose() {
        emitter.off('matches', handleDmgBoss);
        emitter.off('detectSwap', subTurnPlay);
      },
    };
  });
};
