import { FC, useCallback, useEffect, useState } from 'react';

import { LoadingBar } from '@/components/LoadingBar';

import { useLocation } from '@/hooks/useLocation';
import { useRefreshUser } from '@/hooks/useRefreshUser';
import { useRefreshInventory } from '@/hooks/useRefreshInventory';
import { useCheckin } from '@/hooks/useCheckin';
import { usePopup } from '@/hooks/usePopup';

type Props = {};

export const LoadingScreen: FC<Props> = () => {
  const { setLocation } = useLocation();
  const { setErrorPopupVisible } = usePopup();

  const refreshUser = useRefreshUser();
  const refreshInventory = useRefreshInventory();
  const fetchCheckinData = useCheckin();

  const [animationFinished, setAnimationFinished] = useState<Boolean>(false);
  const [dataInited, setDataInited] = useState<Boolean>(false);

  const init = useCallback(async () => {
    try {
      await refreshUser();
      await refreshInventory();
      await fetchCheckinData();

      setDataInited(true);
    } catch (error) {
      setErrorPopupVisible(true);
    }
  }, [fetchCheckinData, refreshInventory, refreshUser, setErrorPopupVisible]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (animationFinished && dataInited) {
      setLocation('/main');
    }
  }, [animationFinished, dataInited, setLocation]);

  return (
    <div className="w-full h-full flex-1 flex flex-col items-center justify-center">
      <div className="w-2/3 absolute bottom-7">
        <LoadingBar onFinished={() => setAnimationFinished(true)} />
      </div>
    </div>
  );
};
