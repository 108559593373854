/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  InputEvent,
  MultiTextureBatch,
  Texture,
  Vector2,
  ViewportInputHandler,
  createGameLoop,
  createViewport,
} from 'gdxts';
import { getAssets } from './Assets';
import { WORLD_WIDTH, WORLD_HEIGHT, ROWS, COLS, BOARD_WIDTH } from './Constants';
import { Manager } from './system-manager';

import { CharacterConfig, Deferred, GemOffsetEffect, ZoomEffect, convertToRadians } from './utils/types';
import Board from './system/engine/Board';
import Engine, { EffectType } from './system/engine/Engine';
import { registerGameRenderSystem } from './system-manager/uiGameRenderSystem';
import { registerHandleGameSystem } from './system-manager/handleGameSystem';
import { emitter } from './utils/emitter';
import { registerBorderRenderSystem } from './system-manager/borderRenderSystem';
import {
  AnimationState,
  AnimationStateData,
  AtlasAttachmentLoader,
  Skeleton,
  SkeletonJson,
  SpineAssetManager,
} from './lib';

export const init = async (canvas: HTMLCanvasElement, roster: [string, string, string, string]) => {
  const viewport = createViewport(canvas, WORLD_WIDTH, WORLD_HEIGHT, {
    crop: false,
  });

  const gl = viewport.getContext();
  const camera = viewport.getCamera();
  camera.setYDown(true);

  const assets = getAssets(gl);

  await assets.finishLoading();
  emitter.emit('finishedLoading');

  const batch = new MultiTextureBatch(gl);
  batch.setYDown(true);

  const inputHandler = new ViewportInputHandler(viewport);

  const gameState = {
    dragging: false,
    highlightedGem: -1,
    stateTime: 0,
    swapping: false,
    moves: 30,
  };

  const board = new Board(8, 8);

  const effects: GemOffsetEffect[] = [];
  const zoomEffect: ZoomEffect[] = [];
  const pendings: Deferred[] = [];
  const wait = (seconds: number) => {
    const deferred = new Deferred(seconds);
    pendings.push(deferred);
    return deferred.promise;
  };

  const rosterName = [
    'rat',
    'ox',
    'tiger',
    'cat',
    'dragon',
    'snake',
    'horse',
    'goat',
    'monkey',
    'chicken',
    'dog',
    'pig',
  ];

  const ROSTER_TYPES: CharacterConfig[] = [
    {
      gemType: rosterName.indexOf(roster[0]),
      atk: 1, // main character
    },
    {
      gemType: rosterName.indexOf(roster[1]),
      atk: 0.5,
    },
    {
      gemType: rosterName.indexOf(roster[2]),
      atk: 0.5,
    },
    {
      gemType: rosterName.indexOf(roster[3]),
      atk: 0.5,
    },
  ];

  const GEM_COLORS = [
    assets.getTexture(`gem${ROSTER_TYPES[0].gemType + 1}` as never),
    assets.getTexture(`gem${ROSTER_TYPES[1].gemType + 1}` as never),
    assets.getTexture(`gem${ROSTER_TYPES[2].gemType + 1}` as never),
    assets.getTexture(`gem${ROSTER_TYPES[3].gemType + 1}` as never),
  ];

  const previousCell = new Vector2(-1, -1);
  const highlightCell = new Vector2(-1, -1);
  const targetCell = new Vector2(-1, -1);

  const [width, height] = [BOARD_WIDTH, BOARD_WIDTH];

  const CELL_SIZE = (width * 1) / ROWS;
  const START_X = width * 0.05;
  const START_Y = height / 2 + ROWS * CELL_SIZE * 0.4;
  const engine = new Engine(COLS, ROWS);
  let selected = false;
  const whiteTex = Texture.createWhiteTexture(gl);

  const NO_OFFSET = {
    offset: 0,
    duration: 1,
    horizontal: true,
    time: 0,
  };

  const bossCharacterConfig = {
    health: 100,
    maxHealth: 100,
  };

  const gameConfig = {
    turnPlay: 30,
  };

  inputHandler.addEventListener(InputEvent.TouchStart, () => {
    const { x, y } = inputHandler.getTouchedWorldCoord();
    previousCell.setVector(highlightCell);
    highlightCell
      .set(x, y)
      .sub(START_X, START_Y)
      .scale(1 / CELL_SIZE);
    highlightCell.set(Math.floor(highlightCell.x), Math.floor(highlightCell.y));

    if (!selected) {
      if (highlightCell.x >= 0 && highlightCell.x < ROWS && highlightCell.y >= 0 && highlightCell.y < COLS) {
        selected = true;
      }
    }
  });

  inputHandler.addEventListener(InputEvent.TouchMove, () => {
    if (!selected) return;

    const { x, y } = inputHandler.getTouchedWorldCoord();

    targetCell
      .set(x, y)
      .sub(START_X, START_Y)
      .scale(1 / CELL_SIZE);
    targetCell.set(Math.floor(targetCell.x), Math.floor(targetCell.y));
    if (targetCell.x !== highlightCell.x || targetCell.y !== highlightCell.y) {
      selected = false;
      try {
        const oldPos = highlightCell.y * ROWS + highlightCell.x;
        const newPos = targetCell.y * ROWS + targetCell.x;
        selected = false;
        engine.swap(oldPos, newPos);
        emitter.emit('detectSwap');
      } catch (e: any) {
        console.log(e.message);
      }
    }
  });
  inputHandler.addEventListener(InputEvent.TouchEnd, () => (selected = false));

  //Spine
  const spineAssetManager = new SpineAssetManager(gl, './characerAssets');
  // const skeletonCharacterJson = spineAssetManager.loadTextureAtlas('mouse/mouse.atlas');
  // spineAssetManager.loadJson('mouse/mouse.json');

  // await spineAssetManager.loadAll();
  let gameEnd = false;

  const manager = new Manager()
    .register('camera', camera)
    .register('viewport', viewport)
    .register('gl', gl)
    .register('assets', assets)
    .register('batch', batch)
    .register('gameState', gameState)
    .register('board', board)
    .register('effects', effects)
    .register('zoomEffect', zoomEffect)
    .register('wait', wait)
    .register('GEM_COLORS', GEM_COLORS)
    .register('engine', engine)
    .register('gameEnd', gameEnd)
    .register('roster', roster)
    .register('gameConfig', gameConfig)
    .register('spineAssetManager', spineAssetManager)
    .register('START_X', START_X)
    .register('START_Y', START_Y)
    .register('CELL_SIZE', CELL_SIZE)
    .register('ROSTER_TYPES', ROSTER_TYPES)
    .register('bossCharacterConfig', bossCharacterConfig)
    .register('inputHandler', inputHandler);

  gl.clearColor(0, 0, 0, 0);
  registerGameRenderSystem(manager);
  registerHandleGameSystem(manager);
  // registerBorderRenderSystem(manager);

  const highlightVerts: number[] = [];
  engine.checkAfter(0.25);

  const loop = createGameLoop((delta: number) => {
    gl.clear(gl.COLOR_BUFFER_BIT);

    batch.setProjection(camera.combined);
    batch.begin();
    // update pendings
    engine.update(delta);
    for (let i = pendings.length - 1; i >= 0; i--) {
      const pending = pendings[i];
      pending.elapsed += delta;
      if (pending.elapsed >= pending.duration) {
        pending.resolve();
        pendings.splice(i, 1);
      }
    }

    batch.setColor(1, 1, 1, 1);
    //border
    // batch.draw(
    //   borderTexture,
    //   START_X - 12.5,
    //   START_Y - borderTexture.height / 2 - 5,
    //   borderTexture.width * 0.65,
    //   borderTexture.height,
    // );
    // batch.draw(
    //   borderTexture,
    //   START_X - 6 - (borderTexture.width * 0.65) / 2,
    //   START_Y + (borderTexture.width / 2) * 0.64 - 13,
    //   borderTexture.width * 0.64 + 8,
    //   borderTexture.height,
    //   (borderTexture.width * 0.65) / 2,
    //   borderTexture.height / 2,
    //   convertToRadians(-90),
    // );
    // batch.draw(
    //   borderTexture,
    //   START_X - 7.5,
    //   START_Y + borderTexture.width * 0.65 - 20,
    //   borderTexture.width * 0.65 + 2.5,
    //   borderTexture.height,
    //   (borderTexture.width * 0.65) / 2,
    //   borderTexture.height / 2,
    //   convertToRadians(180),
    // );
    // batch.draw(
    //   borderTexture,
    //   START_X + (borderTexture.width * 0.64) / 2 - 10,
    //   START_Y + (borderTexture.width / 2) * 0.64 - 14.5,
    //   borderTexture.width * 0.64 + 10,
    //   borderTexture.height,
    //   (borderTexture.width * 0.65) / 2,
    //   borderTexture.height / 2,
    //   convertToRadians(90),
    // );

    for (let y = 0; y < ROWS; y++) {
      for (let x = 0; x < COLS; x++) {
        const type = engine.board.getCell(x, y);
        const pos = y * COLS + x;
        const blockX = START_X + CELL_SIZE * x + CELL_SIZE * 0.1;
        const blockY = START_Y + CELL_SIZE * y + CELL_SIZE * 0.1;
        batch.draw(assets.getTexture('block') as Texture, blockX, blockY, CELL_SIZE * 0.8, CELL_SIZE * 0.8);
        if (type === undefined) {
          continue;
        }
        const color = GEM_COLORS[type % GEM_COLORS.length] as Texture;
        const offset = engine.getCellOffset(pos) || NO_OFFSET;
        let drawX = blockX;
        let drawY = blockY;

        if (offset.horizontal) {
          drawX += (offset.offset * CELL_SIZE * (offset.duration - offset.time)) / offset.duration;
        } else {
          drawY += (offset.offset * CELL_SIZE * (offset.duration - offset.time)) / offset.duration;
        }

        if (drawY + CELL_SIZE > START_Y) {
          let drawHeight = CELL_SIZE * 0.8;
          if (drawY < START_Y) {
            drawHeight = drawHeight - (START_Y - drawY);
            drawY = START_Y + CELL_SIZE * 0.1;
          }
          if (color) {
            batch.draw(color, drawX, drawY, CELL_SIZE * 0.8, drawHeight);
          }
        }

        if (selected && x === highlightCell.x && y === highlightCell.y) {
          highlightVerts.length = 0;
          highlightVerts.push(START_X + CELL_SIZE * x, START_Y + CELL_SIZE * y);
          highlightVerts.push(START_X + CELL_SIZE * x + CELL_SIZE, START_Y + CELL_SIZE * y);
          highlightVerts.push(START_X + CELL_SIZE * x + CELL_SIZE, START_Y + CELL_SIZE * y + CELL_SIZE);
          highlightVerts.push(START_X + CELL_SIZE * x, START_Y + CELL_SIZE * y + CELL_SIZE);
          // drawPolygon(batch, whiteTex, highlightVerts, 10, 1, 0, 0);
          batch.drawVertices(whiteTex, highlightVerts as number[]);
        }
      }
    }

    for (const fx of engine.getEffects()) {
      if (fx.type === EffectType.EXPLODE) {
        const x = fx.pos % COLS;
        const y = Math.floor(fx.pos / COLS);

        const drawX = START_X + CELL_SIZE * x + CELL_SIZE * 0.1;
        const drawY = START_Y + CELL_SIZE * y + CELL_SIZE * 0.1;

        const cellType = fx.data;
        const color = GEM_COLORS[cellType % GEM_COLORS.length] as Texture;

        const scale = (fx.duration - fx.time) / fx.duration;

        batch.draw(
          color,
          drawX,
          drawY,
          CELL_SIZE * 0.8,
          CELL_SIZE * 0.8,
          CELL_SIZE * 0.4,
          CELL_SIZE * 0.4,
          0,
          scale,
          scale,
        );
        batch.setColor(1, 1, 1, 1);
      }
    }

    manager.process(delta);
    batch.end();
  });

  return {
    manager,
    dispose() {
      loop.stop();
      batch.dispose();
      assets.dispose();
      manager.dispose();
      inputHandler.cleanup();
    },
  };
};

export type GameManager = Awaited<ReturnType<typeof init>>['manager'];
