import { useCallback } from 'react';

import gamefoxSDK from '@/utils/gameFoxSDK';
import { useStoreActions } from '@/hooks/useStore';

export const useRefreshUser = () => {
  const setUser = useStoreActions((actions) => actions.setUser);
  const fetchUser = useCallback(async () => {
    const user = await gamefoxSDK.auth();
    setUser(user);
  }, [setUser]);

  return fetchUser;
};
