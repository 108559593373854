import { FC, useMemo } from 'react';

type Props = {
  turns: number;
  energyLevel: number;
};

export const Energy: FC<Props> = ({ turns = 0, energyLevel = 0 }) => {
  const energyLevelUrl = useMemo(() => {
    return `/images/saha_energy_${energyLevel}.png`;
  }, [energyLevel]);

  return (
    <div className="w-1/3 mt-1 flex flex-col items-center">
      <img className="w-full h-auto" src={energyLevelUrl} alt="" />
      <p className="md:text-lg ms:text-sm text-black font-bold mt-1">Có {turns} lượt chơi</p>
    </div>
  );
};
