import { emitter } from '@/GameSystem/utils/emitter';
import Board, { MoveType } from './Board';

/* eslint-disable @typescript-eslint/no-explicit-any */
const SWAP_ANIMATION_TIME = 0.2;
const DROP_ANIMATION_TIME = 0.1;
const EXPLODE_ANIMATION_TIME = 0.2;

export const EffectType = {
  EXPLODE: 1,
};

export const SpecialType = {
  ROW: 1,
  COL: 2,
  ADJACENCE: 3,
  COLOR: 4,
};

export default class Engine {
  effects: any[] = [];
  cellOffsets: any = {};
  enabled = true;
  lastMove: any[] = [];
  checkTimeout = 0;
  shouldCheck = false;
  enableTimeout = 0;
  shouldEnable = false;
  shouldFill = false;
  fillTimeout = 0;
  specials: any[] = [];
  board: Board;

  disable() {
    this.enabled = false;
  }
  enable() {
    this.enabled = true;
  }
  getCellOffset(pos: number) {
    return this.cellOffsets[pos];
  }
  setCellOffset(pos: number, offset: number, duration: number, horizontal: boolean) {
    // TODO: pooling
    this.cellOffsets[pos] = {
      pos,
      offset,
      duration,
      horizontal,
      time: 0,
    };
  }
  spawnEffect(pos: any, type: number, duration: number, data: any) {
    // TODO: pooling
    this.effects.push({
      pos,
      type,
      duration,
      data,
      time: 0,
    });
  }
  constructor(width: number, height: number) {
    this.board = new Board(width, height);
    this.board.randomize();
  }
  clearLastMove() {
    this.lastMove.length = 0;
  }
  storeLastMove(oldPos: any, newPos: any) {
    this.clearLastMove();
    this.lastMove[0] = oldPos;
    this.lastMove[1] = newPos;
  }
  swap(oldPos: number, newPos: number) {
    const board = this.board;
    if (!this.enabled) {
      throw new Error('Currently disabled');
    }
    // validate move
    if (newPos < 0 || newPos >= board.getCellCount() || oldPos < 0 || oldPos >= board.getCellCount()) {
      throw new Error('Invalid value for position.');
    }

    this.doSwap(oldPos, newPos);

    this.storeLastMove(oldPos, newPos);
    this.disable();
    this.checkAfter(SWAP_ANIMATION_TIME);
  }
  swapSpecial(oldPos: number, newPos: number) {
    const oldSpecial = this.specials[oldPos];
    const newSpecial = this.specials[newPos];
    this.specials[oldPos] = newSpecial;
    this.specials[newPos] = oldSpecial;
  }
  doSwap(oldPos: any, newPos: any) {
    const board = this.board;
    const adjacence = board.checkCellsAdjacence(oldPos, newPos);
    if (!adjacence) {
      throw new Error('Cells are not adjacent');
    }
    board.swap(oldPos, newPos);
    this.swapSpecial(oldPos, newPos);

    switch (adjacence) {
      case MoveType.UP:
        this.setCellOffset(oldPos, -1, SWAP_ANIMATION_TIME, false);
        this.setCellOffset(newPos, 1, SWAP_ANIMATION_TIME, false);
        break;
      case MoveType.DOWN:
        this.setCellOffset(oldPos, 1, SWAP_ANIMATION_TIME, false);
        this.setCellOffset(newPos, -1, SWAP_ANIMATION_TIME, false);
        break;
      case MoveType.LEFT:
        this.setCellOffset(oldPos, -1, SWAP_ANIMATION_TIME, true);
        this.setCellOffset(newPos, 1, SWAP_ANIMATION_TIME, true);
        break;
      case MoveType.RIGHT:
        this.setCellOffset(oldPos, 1, SWAP_ANIMATION_TIME, true);
        this.setCellOffset(newPos, -1, SWAP_ANIMATION_TIME, true);
        break;
      default:
    }
  }
  dropAmounts = [];
  cellMatchCount = [];
  cellMatchType = [];
  check() {
    const { board, cellMatchCount, cellMatchType }: any = this;
    this.disable();
    const matches = this.board.findAllMatches();
    if (!matches.length) {
      if (this.lastMove.length) {
        this.doSwap(this.lastMove[1], this.lastMove[0]);
        this.enableAfter(SWAP_ANIMATION_TIME);
        this.clearLastMove();
        return;
      } else {
        this.enable();
        return;
      }
    }
    // TODO: proper matches handling with combo and stuff
    for (const match of matches) {
      for (const cell of match.cells) {
        this.spawnEffect(cell, EffectType.EXPLODE, EXPLODE_ANIMATION_TIME, board.getState(cell));
      }
    }
    for (const match of matches) {
      for (const cell of match.cells) {
        // TODO: break cell function with special effect handling
        // TODO: delay cell breaking with state
        board.setState(cell, undefined);
        this.specials[cell] = undefined;
      }
    }
    // // 5 cell matches
    // for (const match of matches) {
    //   if (match.count === 5) {
    //     let pos = match.cells[2];
    //     if (this.lastMove.length !== 0) {
    //       if (match.cells.includes(this.lastMove[0])) {
    //         pos = this.lastMove[0];
    //       } else if (match.cells.includes(this.lastMove[1])) {
    //         pos = this.lastMove[1];
    //       }
    //     }
    //     this.spawnSpecial(pos, SpecialType.COLOR);
    //     board.setState(pos, 10000);
    //     match.checked = true;
    //   }
    // }
    // adjacence
    cellMatchCount.length = 0;
    cellMatchType.length = 0;
    for (const match of matches) {
      if (match.checked) continue;
      for (const cell of match.cells) {
        cellMatchType[cell] = match.type;
        if (cellMatchCount[cell] === undefined) {
          cellMatchCount[cell] = 1;
        } else {
          cellMatchCount[cell]++;
        }
      }
    }

    //emit count, type gem matches
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      emitter.emit('matches', match.count, match.type);
    }
    // for (let cell = 0; cell < board.state.length; cell++) {
    //   if (cellMatchCount[cell] >= 2) {
    //     this.spawnSpecial(cell, SpecialType.ADJACENCE);
    //     board.setState(cell, cellMatchType[cell]);

    //     for (const match of matches) {
    //       if (match.cells.includes(cell)) {
    //         match.checked = true;
    //       }
    //     }
    //   }
    // }
    // // 4 cell matches
    // for (const match of matches) {
    //   if (match.checked) continue;
    //   if (match.count === 4) {
    //     let pos = match.cells[2];
    //     if (this.lastMove.length !== 0) {
    //       if (match.cells.includes(this.lastMove[0])) {
    //         pos = this.lastMove[0];
    //       } else if (match.cells.includes(this.lastMove[1])) {
    //         pos = this.lastMove[1];
    //       }
    //     }
    //     const specialType = match.horizontal ? SpecialType.COL : SpecialType.ROW;
    //     this.spawnSpecial(pos, specialType);
    //     board.setState(pos, match.type);
    //     match.checked = true;
    //   }
    // }
    this.clearLastMove();
    this.fillAfter(EXPLODE_ANIMATION_TIME);
  }
  spawnSpecial(pos: number, type: number) {
    this.specials[pos] = type;
  }
  getSpecialType(pos: number) {
    return this.specials[pos];
  }
  fillAfter(seconds: number) {
    this.shouldFill = true;
    this.fillTimeout = seconds;
  }
  doFill() {
    const { dropAmounts, board }: any = this;
    // cells filling
    let longestDrop = 0;
    for (let x = 0; x < board.width; x++) {
      dropAmounts.length = 0;
      for (let i = 0; i < board.height; i++) {
        dropAmounts[i] = 0;
      }
      const colData = board.getCol(x);
      let toFill = 0;
      for (let i = colData.length - 1; i >= 0; i--) {
        if (colData[i] === undefined) {
          toFill++;
          for (let y = i - 1; y >= 0; y--) {
            dropAmounts[y]++;
          }
        }
      }
      longestDrop = Math.max(longestDrop, toFill);
      for (let y = colData.length - 1; y >= 0; y--) {
        if (colData[y] === undefined) {
          continue;
        }
        const dropAmount = dropAmounts[y];
        if (dropAmount > 0) {
          const newY = y + dropAmount;
          const newPos = newY * board.width + x;
          const oldPos = y * board.width + x;
          board.setState(newPos, colData[y]);
          board.setState(oldPos, undefined);
          this.specials[newPos] = this.specials[oldPos];
          this.specials[oldPos] = undefined;
          this.setCellOffset(newPos, -dropAmount, dropAmount * DROP_ANIMATION_TIME, false);
        }
      }
      for (let y = 0; y < toFill; y++) {
        const pos = y * board.width + x;
        board.setRandomState(pos);
        this.setCellOffset(pos, -toFill, toFill * DROP_ANIMATION_TIME, false);
      }
    }
    this.checkAfter(longestDrop * DROP_ANIMATION_TIME * 1.1);
  }
  checkAfter(seconds: number) {
    this.shouldCheck = true;
    this.checkTimeout = seconds;
  }
  enableAfter(seconds: number) {
    this.shouldEnable = true;
    this.enableTimeout = seconds;
  }
  update(delta: number) {
    if (this.shouldCheck) {
      if (this.checkTimeout > 0) {
        this.checkTimeout -= delta;
      } else {
        this.shouldCheck = false;
        this.checkTimeout = 0;
        this.check();
      }
    }

    if (this.shouldEnable) {
      if (this.enableTimeout > 0) {
        this.enableTimeout -= delta;
      } else {
        this.shouldEnable = false;
        this.enableTimeout = 0;
        this.enable();
      }
    }

    if (this.shouldFill) {
      if (this.fillTimeout > 0) {
        this.fillTimeout -= delta;
      } else {
        this.shouldFill = false;
        this.fillTimeout = 0;
        this.doFill();
      }
    }

    for (const pos in this.cellOffsets) {
      const offset = this.cellOffsets[pos];
      offset.time += delta;
      if (offset.time >= offset.duration) {
        delete this.cellOffsets[pos];
      }
    }

    for (let i = this.effects.length - 1; i >= 0; i--) {
      const fx = this.effects[i];
      fx.time += delta;
      if (fx.time >= fx.duration) {
        this.effects.splice(i, 1);
      }
    }
  }
  getCellStates() {
    return this.board.state;
  }
  getEffects() {
    return this.effects;
  }
}
