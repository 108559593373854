import { Texture } from 'gdxts';
import { GameManager } from '../main';
import { WORLD_WIDTH } from '../Constants';

export const registerGameRenderSystem = (manager: GameManager) => {
  const batch = manager.context.batch;
  const assets = manager.context.assets;
  const rosterType = manager.context.ROSTER_TYPES;

  const rosterScale = 0.75;

  const bossTexture = assets.getTexture('boss1') as Texture;
  const bossScale = 0.7;
  const bossCharacterConfig = manager.context.bossCharacterConfig;

  const whiteTexture = Texture.createWhiteTexture(manager.context.gl);
  const gameConfig = manager.context.gameConfig;
  const turnPlayBoard = manager.context.assets.getTexture('turn_play_board') as Texture;
  const turnPlayScale = 0.65;

  const exoBold = assets.getFont('exoBold');
  exoBold.data.scale(-0.25);

  manager.addSystem(() => {
    return {
      process() {
        if (manager.context.gameEnd) return;
        for (let i = 0; i < rosterType.length; i++) {
          const rosterTexture = assets.getTexture(`characterGem${rosterType[i].gemType + 1}` as never);

          if (rosterTexture) {
            batch.draw(rosterTexture, -5 + 160 * rosterScale * i, 250 + i, 150, 120);
          }
        }

        batch.draw(bossTexture, 110, 0, bossTexture.width * bossScale, bossTexture.height * bossScale);
        batch.draw(
          turnPlayBoard,
          WORLD_WIDTH - turnPlayBoard.width * turnPlayScale - 10,
          25,
          turnPlayBoard.width * turnPlayScale,
          turnPlayBoard.height * turnPlayScale,
        );

        // Draw boss health bar
        batch.setColor(0.5, 0.5, 0.5, 1);
        batch.draw(whiteTexture, 110, 0, bossTexture.width * bossScale, 10);
        batch.setColor(1, 0, 0, 1);
        batch.draw(
          whiteTexture,
          110,
          0,
          (bossTexture.width * bossScale * bossCharacterConfig.health) / bossCharacterConfig.maxHealth,
          10,
        );
        batch.setColor(1, 1, 1, 1);
        exoBold.draw(
          batch,
          gameConfig.turnPlay < 10 ? `0${gameConfig.turnPlay}` : `${gameConfig.turnPlay}`,
          WORLD_WIDTH - 66.5,
          60,
          100,
        );
      },
      dispose() {},
    };
  });
};
