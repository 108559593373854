import { FC, useCallback, useMemo, useEffect } from 'react';

import { useLocation } from '@/hooks/useLocation';
import { usePopup } from '@/hooks/usePopup';
import { useStoreState, useStoreActions } from '@/hooks/useStore';
import { useRefreshInventory } from '@/hooks/useRefreshInventory';

type Props = {
  isVisible: boolean;
};

export const VictoryPopup: FC<Props> = ({ isVisible }) => {
  const { setVictoryPopupVisible } = usePopup();
  const { setLocation } = useLocation();
  const refreshInventory = useRefreshInventory();
  const setGiftData = useStoreActions((actions) => actions.setGiftData);

  const giftData = useStoreState((state) => state.giftData);

  useEffect(() => {
    if (!isVisible) {
      setGiftData(null);
    }
  }, [isVisible, setGiftData]);

  const popupInfo = useMemo(() => {
    if (!giftData) return null;

    const energy = `/images/saha_energy_${giftData.winCount}.png`;

    let rewardImage = '';
    let rewardDescription = '';
    let redirect = '/collection';

    switch (giftData.itemType) {
      case 'TY_1':
        rewardImage = '/images/rat_piece_1.png';
        rewardDescription = '1 mảnh ghép Tý';
        break;
      case 'TY_2':
        rewardImage = '/images/rat_piece_2.png';
        rewardDescription = '1 mảnh ghép Tý';
        break;
      case 'SUU_1':
        rewardImage = '/images/ox_piece_1.png';
        rewardDescription = '1 mảnh ghép Sửu';
        break;
      case 'SUU_2':
        rewardImage = '/images/ox_piece_2.png';
        rewardDescription = '1 mảnh ghép Sửu';
        break;
      case 'DAN_1':
        rewardImage = '/images/tiger_piece_1.png';
        rewardDescription = '1 mảnh ghép Dần';
        break;
      case 'DAN_2':
        rewardImage = '/images/tiger_piece_2.png';
        rewardDescription = '1 mảnh ghép Dần';
        break;
      case 'MAO_1':
        rewardImage = '/images/cat_piece_1.png';
        rewardDescription = '1 mảnh ghép Mão';
        break;
      case 'MAO_2':
        rewardImage = '/images/cat_piece_2.png';
        rewardDescription = '1 mảnh ghép Mão';
        break;
      case 'THIN_1':
        rewardImage = '/images/dragon_piece_1.png';
        rewardDescription = '1 mảnh ghép Thìn';
        break;
      case 'THIN_2':
        rewardImage = '/images/dragon_piece_2.png';
        rewardDescription = '1 mảnh ghép Thìn';
        break;
      case 'RAN_1':
        rewardImage = '/images/snake_piece_1.png';
        rewardDescription = '1 mảnh ghép Tỵ';
        break;
      case 'RAN_2':
        rewardImage = '/images/snake_piece_2.png';
        rewardDescription = '1 mảnh ghép Tỵ';
        break;
      case 'NGO_1':
        rewardImage = '/images/horse_piece_1.png';
        rewardDescription = '1 mảnh ghép Ngọ';
        break;
      case 'NGO_2':
        rewardImage = '/images/horse_piece_2.png';
        rewardDescription = '1 mảnh ghép Ngọ';
        break;
      case 'MUI_1':
        rewardImage = '/images/goat_piece_1.png';
        rewardDescription = '1 mảnh ghép Mùi';
        break;
      case 'MUI_2':
        rewardImage = '/images/goat_piece_2.png';
        rewardDescription = '1 mảnh ghép Mùi';
        break;
      case 'THAN_1':
        rewardImage = '/images/monkey_piece_1.png';
        rewardDescription = '1 mảnh ghép Thân';
        break;
      case 'THAN_2':
        rewardImage = '/images/monkey_piece_2.png';
        rewardDescription = '1 mảnh ghép Thân';
        break;
      case 'DAU_1':
        rewardImage = '/images/chicken_piece_1.png';
        rewardDescription = '1 mảnh ghép Dậu';
        break;
      case 'DAU_2':
        rewardImage = '/images/chicken_piece_2.png';
        rewardDescription = '1 mảnh ghép Dậu';
        break;
      case 'TUAT_1':
        rewardImage = '/images/dog_piece_1.png';
        rewardDescription = '1 mảnh ghép Tuất';
        break;
      case 'TUAT_2':
        rewardImage = '/images/dog_piece_2.png';
        rewardDescription = '1 mảnh ghép Tuất';
        break;
      case 'HOI_1':
        rewardImage = '/images/pig_piece_1.png';
        rewardDescription = '1 mảnh ghép Hợi';
        break;
      case 'HOI_2':
        rewardImage = '/images/pig_piece_2.png';
        rewardDescription = '1 mảnh ghép Hợi';
        break;
    }

    if (giftData.itemType.startsWith('POINT_')) {
      rewardImage = '/images/reward_point.png';

      let value = giftData.itemType.replace('POINT_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `${value} Điểm SHB REWARD`;
      redirect = '/reward';
    }

    if (giftData.itemType.startsWith('CASH_')) {
      rewardImage = '/images/reward_cash.png';

      let value = giftData.itemType.replace('CASH_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `Tiền mặt ${value} VND`;
      redirect = '/reward';
    }

    if (giftData.itemType.startsWith('V_TOPUP_')) {
      rewardImage = '/images/reward_voucher.png';

      let value = giftData.itemType.replace('V_TOPUP_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `Voucher ${value} VND`;
      redirect = '/reward';
    }

    if (giftData.itemType === 'V_GOTIT') {
      rewardImage = '/images/reward_gotit.png';
      rewardDescription = 'Voucher Gotit';
      redirect = '/reward';
    }

    return {
      energy,
      rewardImage,
      rewardDescription,
      redirect,
    };
  }, [giftData]);

  const handleViewCollection = useCallback(
    (path: string) => {
      setVictoryPopupVisible(false);
      setLocation(path);
    },
    [setLocation, setVictoryPopupVisible],
  );

  const handleContinuePlay = useCallback(async () => {
    setVictoryPopupVisible(false);
    await refreshInventory();
    setLocation('/main');
  }, [refreshInventory, setLocation, setVictoryPopupVisible]);

  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <img className="absolute top-0 w-2/3 h-auto" src="/images/popup_star.png" alt="" />
        <img className="absolute top-0 translate-y-2/5 w-full h-auto" src="/images/popup_lighting.png" alt="" />
        <div
          style={{ width: '95%', height: '75%' }}
          className="max-w-screen-ml bg-popup-victory bg-no-repeat bg-center bg-contain flex flex-col items-center justify-center relative"
        >
          <img className="w-1/3 mb-4" src={popupInfo?.energy} alt="" />
          <p className="text-sm text-white font-bold">Chúc mừng bạn đã chiến thắng</p>
          <p className="text-sm ml:text-base text-white font-bold">và nhận được {popupInfo?.rewardDescription}</p>
          <div className="w-52 ms:mt-8 mm:mt-16 relative flex items-center justify-center">
            <img className="w-full absolute" src="/images/slot_lighting_orange.png" alt="" />
            <img className="h-20 w-auto absolute translate-y-2" src={popupInfo?.rewardImage} alt="" />
          </div>
        </div>

        <div className="w-full flex items-center absolute bottom-10">
          <div
            onClick={() => handleViewCollection(popupInfo?.redirect || '/collection')}
            className="flex flex-1 items-center justify-center pl-4 pr-2 cursor-pointer hover:opacity-80"
          >
            <img className="w-full h-auto sm:w-2/3" src="/images/button_inventory.png" alt="" />
          </div>
          <div
            onClick={() => handleContinuePlay()}
            className="flex flex-1 items-center justify-center pl-2 pr-4 cursor-pointer hover:opacity-80"
          >
            <img className="w-full h-auto sm:w-2/3" src="/images/button_continue.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
