import { FC, useEffect, useMemo } from 'react';

import { useLocation } from '@/hooks/useLocation';
import { usePopup } from '@/hooks/usePopup';
import { useStoreState, useStoreActions } from '@/hooks/useStore';

type Props = {
  isVisible: boolean;
};
export const LuckySpinPopup: FC<Props> = ({ isVisible }) => {
  const { setLocation } = useLocation();
  const { setLuckySpinPopupVisible } = usePopup();
  const setGiftData = useStoreActions((actions) => actions.setGiftData);

  const giftData = useStoreState((state) => state.giftData);

  useEffect(() => {
    if (!isVisible) {
      setGiftData(null);
    }
  }, [isVisible, setGiftData]);

  const popupInfo = useMemo(() => {
    if (!giftData) return null;

    const energy = `/images/saha_energy_${giftData.winCount}.png`;

    let rewardImage = '';
    let rewardDescription = '';
    let redirect = '/collection';

    switch (giftData.itemType) {
      case 'TY_1':
        rewardImage = '/images/rat_piece_1.png';
        rewardDescription = '1 mảnh ghép Tý';
        break;
      case 'TY_2':
        rewardImage = '/images/rat_piece_2.png';
        rewardDescription = '1 mảnh ghép Tý';
        break;
      case 'SUU_1':
        rewardImage = '/images/ox_piece_1.png';
        rewardDescription = '1 mảnh ghép Sửu';
        break;
      case 'SUU_2':
        rewardImage = '/images/ox_piece_2.png';
        rewardDescription = '1 mảnh ghép Sửu';
        break;
      case 'DAN_1':
        rewardImage = '/images/tiger_piece_1.png';
        rewardDescription = '1 mảnh ghép Dần';
        break;
      case 'DAN_2':
        rewardImage = '/images/tiger_piece_2.png';
        rewardDescription = '1 mảnh ghép Dần';
        break;
      case 'MAO_1':
        rewardImage = '/images/cat_piece_1.png';
        rewardDescription = '1 mảnh ghép Mão';
        break;
      case 'MAO_2':
        rewardImage = '/images/cat_piece_2.png';
        rewardDescription = '1 mảnh ghép Mão';
        break;
      case 'THIN_1':
        rewardImage = '/images/dragon_piece_1.png';
        rewardDescription = '1 mảnh ghép Thìn';
        break;
      case 'THIN_2':
        rewardImage = '/images/dragon_piece_2.png';
        rewardDescription = '1 mảnh ghép Thìn';
        break;
      case 'RAN_1':
        rewardImage = '/images/snake_piece_1.png';
        rewardDescription = '1 mảnh ghép Tỵ';
        break;
      case 'RAN_2':
        rewardImage = '/images/snake_piece_2.png';
        rewardDescription = '1 mảnh ghép Tỵ';
        break;
      case 'NGO_1':
        rewardImage = '/images/horse_piece_1.png';
        rewardDescription = '1 mảnh ghép Ngọ';
        break;
      case 'NGO_2':
        rewardImage = '/images/horse_piece_2.png';
        rewardDescription = '1 mảnh ghép Ngọ';
        break;
      case 'MUI_1':
        rewardImage = '/images/goat_piece_1.png';
        rewardDescription = '1 mảnh ghép Mùi';
        break;
      case 'MUI_2':
        rewardImage = '/images/goat_piece_2.png';
        rewardDescription = '1 mảnh ghép Mùi';
        break;
      case 'THAN_1':
        rewardImage = '/images/monkey_piece_1.png';
        rewardDescription = '1 mảnh ghép Thân';
        break;
      case 'THAN_2':
        rewardImage = '/images/monkey_piece_2.png';
        rewardDescription = '1 mảnh ghép Thân';
        break;
      case 'DAU_1':
        rewardImage = '/images/chicken_piece_1.png';
        rewardDescription = '1 mảnh ghép Dậu';
        break;
      case 'DAU_2':
        rewardImage = '/images/chicken_piece_2.png';
        rewardDescription = '1 mảnh ghép Dậu';
        break;
      case 'TUAT_1':
        rewardImage = '/images/dog_piece_1.png';
        rewardDescription = '1 mảnh ghép Tuất';
        break;
      case 'TUAT_2':
        rewardImage = '/images/dog_piece_2.png';
        rewardDescription = '1 mảnh ghép Tuất';
        break;
      case 'HOI_1':
        rewardImage = '/images/pig_piece_1.png';
        rewardDescription = '1 mảnh ghép Hợi';
        break;
      case 'HOI_2':
        rewardImage = '/images/pig_piece_2.png';
        rewardDescription = '1 mảnh ghép Hợi';
        break;
    }

    if (giftData.itemType.startsWith('POINT_')) {
      rewardImage = '/images/reward_point.png';

      let value = giftData.itemType.replace('POINT_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `${value} Điểm SHB REWARD`;
      redirect = '/reward';
    }

    if (giftData.itemType.startsWith('CASH_')) {
      rewardImage = '/images/reward_cash.png';

      let value = giftData.itemType.replace('CASH_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `Tiền mặt ${value} VND`;
      redirect = '/reward';
    }

    if (giftData.itemType.startsWith('V_TOPUP_')) {
      rewardImage = '/images/reward_voucher.png';

      let value = giftData.itemType.replace('V_TOPUP_', '');

      if (value.includes('K')) {
        value = value.replace('K', ',000');
      }

      rewardDescription = `Voucher ${value} VND`;
      redirect = '/reward';
    }

    if (giftData.itemType === 'IPHONE_15_PRO') {
      rewardImage = '/images/reward_iphone.png';
      rewardDescription = 'IPhone 15 Pro';
      redirect = '/reward';
    }

    if (giftData.itemType === 'V_GOTIT') {
      rewardImage = '/images/reward_gotit.png';
      rewardDescription = 'Voucher Gotit';
      redirect = '/reward';
    }

    return {
      energy,
      rewardImage,
      rewardDescription,
      redirect,
    };
  }, [giftData]);
  if (!isVisible) return null;

  return (
    <div style={{ background: '#33333380' }} className="fixed inset-0 z-20">
      <div className="w-full h-full flex flex-col items-center justify-center relative">
        <div
          style={{ width: '80%', height: '50%' }}
          className="max-w-screen-ml flex flex-col items-center justify-center relative"
        >
          <img className="w-1/3 h-auto mb-4 z-10" src={popupInfo?.rewardImage} alt="" />
          <p className="text-sm ml:text-base text-orange-80 font-bold z-10">Chúc mừng bạn đã nhận được </p>
          <p className="text-sm ml:text-base text-orange-80 font-bold z-10">{popupInfo?.rewardDescription}</p>
          <div className="flex flex-1 items-center justify-center cursor-pointer hover:opacity-50 z-10 absolute bottom-3">
            <img
              onClick={() => {
                setLocation(popupInfo?.redirect || '/reward');
                setLuckySpinPopupVisible(false);
              }}
              className="w-3/5 h-auto sm:w-2/3"
              src="/images/button_claim_reward.png"
              alt=""
            />
          </div>
          <img src="/images/popup_scroll.png" alt="" className="w-full h-auto absolute inset-0" />
        </div>
      </div>
    </div>
  );
};
